import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import firebase from "../components/Firebase";
import CardTitolo from "../components/CardTitolo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function () {
  firebase.auth().languageCode = "it";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [telefono, setTelefono] = useState("");
  const [messageError1, setMessageError1] = useState(null);
  const [messageError2, setMessageError2] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  //const [recapchaValue, setRecapchaValue] = useState();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    setRecaptchaVerifier(
      new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "normal",
        callback: (response) => {
          //setRecapchaValue(response);
          console.log("reCAPTCHA solved", response);
        },
        "expired-callback": () => {
          console.log("recaptcha expired");
        },
      })
    );
  }, []);

  useEffect(() => {
    if (recaptchaVerifier !== null) {
      recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
    }
  }, [recaptchaVerifier]);

  const onAccessoConEmail = (e) => {
    e.preventDefault();

    //LOGIN
    setLoading1(true);
    setMessageError1(null);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        setLoading1(false);
        return <Redirect to="/" />;
      })
      .catch((error) => {
        console.log("errore login", error);

        switch (error.code) {
          case "auth/user-not-found":
            setMessageError1("Email non valida");
            break;
          case "auth/wrong-password":
            setMessageError1("Password non valida");
            break;
          default:
            setMessageError1(
              "Si sono verificati errori in fase di login. Riprova"
            );
            break;
        }

        setLoading1(false);
      });
  };

  const onAccessoConTelefono = (e) => {
    e.preventDefault();

    setLoading2(true);
    setMessageError2(null);

    debugger;
    firebase
      .auth()
      .signInWithPhoneNumber("+39" + telefono, recaptchaVerifier)
      .then((res) => {
        debugger;
        setLoading2(false);

        let codiceConferma = prompt("Inserisci il codice ricevuto via SMS", "");

        if (!codiceConferma) return;

        res
          .confirm(codiceConferma)
          .then((ok) => {
            return <Redirect to="/" />;
          })
          .catch((err) => {
            setMessageError2(
              "Si è verificato un errore durante l'accesso. Riprova."
            );
          });
      })
      .catch((error) => {
        debugger;
        setMessageError2(error.message);
      });

    setLoading2(false);
  };

  /*
return (
      <Redirect to="/" />
    )
*/

  return (
    <div className="container">
      <CardTitolo
        titolo="LOGIN"
        sottotitolo="Accedi con le tue credenziali e scopri tutti i prezzi del nostro Shop"
      />

      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="pills-email-tab"
            data-toggle="pill"
            href="#pills-home"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            <FontAwesomeIcon icon={["fas", "envelope"]} />{' '}Email
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="pills-phone-tab"
            data-toggle="pill"
            href="#pills-profile"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            <FontAwesomeIcon icon={["fas", "phone"]} />{' '}Telefono
          </a>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-email-tab"
        >
          <div className="card">
            
            <div className="card-body">
              <form onSubmit={onAccessoConEmail}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    required
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value);
                    }}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    required
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.currentTarget.value);
                    }}
                  />
                </div>

                <div className="form-group row">
                  {messageError1 ? (
                    <p style={{ fontWeight: "bold", color: "red" }}>
                      {messageError1}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn-success btn-block"
                  disabled={loading1}
                >
                  {loading1 ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      />{" "}
                      <span>ACCESSO IN CORSO...</span>
                    </>
                  ) : (
                    <span>ACCEDI</span>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-phone-tab"
        >
          <div className="card">
            
            <div className="card-body">
              <form onSubmit={onAccessoConTelefono}>
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="email">Telefono</label>
                      <input
                        type="number"
                        required
                        className="form-control"
                        value={telefono}
                        onChange={(e) => {
                          setTelefono(e.currentTarget.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div id="recaptcha-container" />

                <div className="form-group row">
                  {messageError2 ? (
                    <p style={{ fontWeight: "bold", color: "red" }}>
                      {messageError2}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>

                

                
                <button
                  type="submit"
                  className="btn btn-success btn-block"
                  disabled={loading2}
                >
                  {loading2 ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      />{" "}
                      <span>ACCESSO IN CORSO...</span>
                    </>
                  ) : (
                    <span>ACCEDI</span>
                  )}
                </button>
                

              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-2">
        <p>
          Non sei ancora registrato?{" "}
          <a className="btn btn-link p-0" href="/registrazione" role="button">
            Registrati
          </a>
          !
        </p>
      </div>


    </div>
  );
}
