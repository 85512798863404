import React, { useState, useEffect, createRef, useContext } from "react";
import { motion, useSpring } from "framer-motion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import { ParametersContext } from "../components/ParametersContext";
const genericFunction = require("../genericFunctions");

export default function ({ carrello, onClearCart, pippo, navigation }) {
  //const [cartFull, setCartFull] = useState(false);
  // const [showOrder, setShowOrder] = useState(false);
  const [PARAMETERS, setParameters] = useContext(ParametersContext);
  const [mostraPrezzi, setMostraPrezzi] = useState(false);
  const [prezzoFinale, setPrezzoFinale] = useState(0);
  const [DELIVERY_PRICE, setCostoConsegna] = useState(2);
  const [MIN_PRODUCTS_IN_CART, setMinProdottiCarrello] = useState();
  const [prezzoFinaleCalcolato, setPrezzoFinaleCalcolato] = useState();
  
  let RifCollapse = createRef();

  useEffect(() => {
    //debugger;
    /*
    if (carrello.filter((el) => el.quantity > 0).length > 0) {
      setCartFull(true);
    } else {
      setCartFull(false);
    }
    */

    if (carrello.length > 0 && carrello[0].hasOwnProperty("price")) {
      setMostraPrezzi(true);
    } else {
      setMostraPrezzi(false);
    }

    if (carrello.length > 0) {
      calcoloPrezzoFinale();
    }

    sessionStorage.carrello = JSON.stringify(carrello);
  });

  
  useEffect(() => {
    if(PARAMETERS.length) {
      const min = PARAMETERS.filter( el => el.key === "MIN_PRODUCTS_IN_CART")[0].value;
      const costo = PARAMETERS.filter( el => el.key === "DELIVERY_PRICE")[0].value;
      setMinProdottiCarrello(min);
      setCostoConsegna(costo);
    }
  }, [PARAMETERS]);


    useEffect(() => {
    
      if(DELIVERY_PRICE && prezzoFinale) {
        setPrezzoFinaleCalcolato(genericFunction.toFixedPrice(parseFloat(DELIVERY_PRICE) + parseFloat(prezzoFinale)));
      }

  }, [DELIVERY_PRICE, prezzoFinale]);

  useEffect(() => {
    calcoloPrezzoFinale();
  }, [mostraPrezzi]);

  const calcoloPrezzoFinale = () => {
    //debugger;

    if (mostraPrezzi) {
      let totale = 0;

      carrello.forEach((el, i) => {
        totale += el.price * el.quantity;
      });

      // arrotondo e miglioro
      totale = parseFloat(totale).toFixed(3);

      setPrezzoFinale(totale);
      sessionStorage.prezzoFinale = totale;
      //cookie.save('prezzoFinale', totale);
    } else {
      setPrezzoFinale(0);
      //sessionStorage.prezzoFinale = 0;
      //cookie.save('prezzoFinale', 0);
      sessionStorage.prezzoFinale = 0;
    }
  };

  /*
  const calcoloCostoConsegna = () => {
    if (parseFloat(prezzoFinale) <= 20) {
      setCostoConsegna(4);
      sessionStorage.costoConsegna = 4;
      //cookie.save('costoConsegna', 4);
    } else if (prezzoFinale > 20 && prezzoFinale <= 40) {
      setCostoConsegna(2);
      sessionStorage.costoConsegna = 2;
      //cookie.save('costoConsegna', 2);
    } else {
      setCostoConsegna(0);
      sessionStorage.costoConsegna = 0;
      //cookie.save('costoConsegna', 0);
    }
  };
  */

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const disegnaTabellaRiepilogo = () => {
    
    if(isMobile) {

        if(mostraPrezzi) {
          return (
            <div className="d-flex flex-column align-items-center justify-content-center">
            <h5 className="font-weight-bold">Il tuo ordine</h5>
            <table className="table table-sm table-bordered table-striped">
              <thead>
                <tr className="table-success text-nowrap text-center">
                  <th>Prodotto</th>
                  <th>Quantità</th>
                  <th>Prezzo (€)</th>
                </tr>
              </thead>
              <tbody>
              {carrello.map((prodotto, i) => (
                  <tr key={i} className="text-center">
                    <td>{prodotto.name}</td>
                    <td>
                      {
                        prodotto.unit === "kg" ?
                        <span>{prodotto.quantity.toFixed(3)}</span>
                        :
                        <span>{prodotto.quantity}</span>
                      }
                    </td>
                    <td>
                      <span>
                          {genericFunction.toFixedPrice(prodotto.price * prodotto.quantity)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="container">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">COSTO CONSEGNA{' '}<FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" size="sm" /></span>
              <span className="font-weight-bold">{DELIVERY_PRICE}{' '}€</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">TOTALE</span>
                <span className="font-weight-bold">
                  {prezzoFinaleCalcolato}{' '}€
                </span>
              </li>
            </ul>
            <div className="mt-2">
              <dd>
                <FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" size="sm" />{' '}<small>Se sceglierai il giorno di consegna gratuito per il tuo comune il costo di consegna sarà <b>GRATUITO</b>!</small>
              </dd>
            </div>
            </div>
          </div>
          )
        }
        else {
          return (
            <div className="d-flex flex-column align-items-center justify-content-center">
            <h5 className="font-weight-bold">Il tuo ordine</h5>
            <table className="table table-sm table-bordered table-striped">
              <thead>
                <tr className="table-success text-nowrap text-center">
                  <th>Prodotto</th>
                  <th>Quantità</th>
                  <th>Unit.</th>
                </tr>
              </thead>
              <tbody>
              {carrello.map((prodotto, i) => (
                  <tr key={i} className="text-center">
                    <td>{prodotto.name}</td>
                    <td>
                      {
                        prodotto.unit === "kg" ?
                        <span>{prodotto.quantity.toFixed(3)}</span>
                        :
                        <span>{prodotto.quantity}</span>
                      }
                    </td>
                    <td>{prodotto.unit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="container">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">COSTO CONSEGNA{' '}<FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" size="sm" /></span>
              <span className="font-weight-bold">{DELIVERY_PRICE}{' '}€</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">TOTALE</span>
                <span className="badge badge-pill">
                  <FontAwesomeIcon icon={["fas", "asterisk"]} color="red" size="sm" />
                </span>
              </li>
            </ul>
            <div className="mt-2">
              <dd>
                <FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" size="sm" />{' '}<small>Se sceglierai il giorno di consegna gratuito per il tuo comune il costo di consegna sarà <b>GRATUITO</b>!</small>
              </dd>
              <dd>
                <FontAwesomeIcon icon={["fas", "asterisk"]} size="sm" color="red" size="sm" />{' '}<small>Devi essere registrato per vedere i prezzi!</small>
              </dd>
            </div>
            </div>
          </div>
          )
        }
    } else {
      
      if(mostraPrezzi) {
        return (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h5 className="font-weight-bold">Il tuo ordine</h5>
            <table className="table table-sm table-bordered table-striped">
              <thead>
                <tr className="table-success text-nowrap text-center">
                  <th>Prodotto</th>
                  <th>Quantità</th>
                  <th>Costo Unit.</th>
                  <th>Prezzo (€)</th>
                </tr>
              </thead>
              <tbody>
              {carrello.map((prodotto, i) => (
                  <tr key={i} className="text-center">
                    <td>{prodotto.name}</td>
                    <td>
                      {
                        prodotto.unit === "kg" ?
                        <span>{prodotto.quantity.toFixed(3)}</span>
                        :
                        <span>{prodotto.quantity}</span>
                      }
                    </td>
                    <td>
                      <span>{genericFunction.toFixedPrice(prodotto.price)} <small>€/{prodotto.unit}</small></span>
                    </td>
                    <td>
                      <span>
                          {genericFunction.toFixedPrice(prodotto.price * prodotto.quantity)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="container">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">COSTO CONSEGNA{' '}<FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" size="sm" /></span>
              <span className="font-weight-bold">{DELIVERY_PRICE}{' '}€</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">TOTALE</span>
                <span className="font-weight-bold">
                  {prezzoFinaleCalcolato}{' '}€
                </span>
              </li>
            </ul>
            <div className="mt-2">
              <dd>
                <FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" size="sm" />{' '}<small>Se sceglierai il giorno di consegna gratuito per il tuo comune il costo di consegna sarà <b>GRATUITO</b>!</small>
              </dd>
            </div>
            </div>
          </div>
        )
      }
      else {
        return (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h5 className="font-weight-bold">Il tuo ordine</h5>
            <table className="table table-sm table-bordered table-striped">
              <thead>
                <tr className="table-success text-nowrap text-center">
                  <th>Prodotto</th>
                  <th>Quantità</th>
                  <th>Unit.</th>
                </tr>
              </thead>
              <tbody>
              {carrello.map((prodotto, i) => (
                  <tr key={i} className="text-center">
                    <td>{prodotto.name}</td>
                    <td>
                      {
                        prodotto.unit === "kg" ?
                        <span>{prodotto.quantity.toFixed(3)}</span>
                        :
                        <span>{prodotto.quantity}</span>
                      }
                    </td>
                    <td>{prodotto.unit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="container">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">COSTO CONSEGNA{' '}<FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" size="sm" /></span>
              <span className="font-weight-bold">{DELIVERY_PRICE}{' '}€</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">TOTALE</span>
                <span className="badge badge-pill">
                  <FontAwesomeIcon icon={["fas", "asterisk"]} color="red" size="sm" />
                </span>
              </li>
            </ul>
            <div className="mt-2">
              <dd>
                <FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" size="sm" />{' '}<small>Se sceglierai il giorno di consegna gratuito per il tuo comune il costo di consegna sarà <b>GRATUITO</b>!</small>
              </dd>
              <dd>
                <FontAwesomeIcon icon={["fas", "asterisk"]} size="sm" color="red" size="sm" />{' '}<small>Devi essere registrato per vedere i prezzi!</small>
              </dd>
            </div>
            
            </div>
          </div>
        )
      }

    }
  }

  const disegnaElementiCarrello = () => {
    if (carrello.length === 0) {
      return (
        // <div className="text-center">
        //   <h6>Ancora nessun prodotto aggiunto al carrello</h6>
        // </div>
        <></>
      );
    } else {
      return (
        <>
          <div id="card-riepilogo-ordine" className="table-responsive p-2 my-4 container" style={{ display: "block" }}>

            { disegnaTabellaRiepilogo() }

            {/* <table className="table table-sm table-bordered table-striped w-auto">
              <thead>
                <tr className="table-success text-nowrap text-center">
                  <th scope="col">Prodotto</th>
                  <th scope="col">Quantità</th>
                  <th scope="col">Costo Unit.</th>
                  <th scope="col">Prezzo (€)</th>
                </tr>
              </thead>
              <tbody>
                {carrello.map((prodotto, i) => (
                  <tr key={i} className="text-center">
                    <td>{prodotto.name}</td>
                    <td>{prodotto.quantity}</td>
                    <td>
                      {
                        mostraPrezzi ?
                      <span>{genericFunction.toFixedPrice(prodotto.price)} €/ <small>{prodotto.unit}</small></span>
                        :
                        <>
                        <FontAwesomeIcon icon={["fas", "asterisk"]} color="red" size="sm" />
                        <span>{' '}€/<small>{prodotto.unit}</small></span>
                         </>
                      }

                    </td>
                    <td>
                      {mostraPrezzi ? (
                        <span>
                          {genericFunction.toFixedPrice(prodotto.price * prodotto.quantity)}
                        </span>
                      ) : (
                        <FontAwesomeIcon icon={["fas", "asterisk"]} color="red" size="sm" />
                        
                      )}
                    </td>
                  </tr>
                ))}

                <tr className="text-center">
                  <td colSpan="3" className="font-weight-bold">
                    Consegna{' '}<FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" />
                  </td>
                  <td>
                    {mostraPrezzi ? (
                      <span>{genericFunction.toFixedPrice(costoConsegna)} €</span>
                    ) : (
                      <FontAwesomeIcon icon={["fas", "asterisk"]} color="red" size="sm" />
                    )}
                  </td>
                </tr>
                <tr className="text-center">
                  <td colSpan="3" className="font-weight-bold">
                    Totale
                  </td>
                  <td>
                    {mostraPrezzi ? (
                      <span className="font-weight-bold">
                        
                        {
                        genericFunction.toFixedPrice(parseFloat(costoConsegna) + parseFloat(prezzoFinale))}{" "}
                        €
                      </span>
                    ) : (
                      <FontAwesomeIcon icon={["fas", "asterisk"]} color="red" size="sm" />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <p><FontAwesomeIcon icon="info-circle" className="fa-1x" color="cadetblue" />{' '}<small>Se sceglierai il giorno di consegna gratuito per il tuo comune il costo sarà <b>0</b> euro!</small></p>
            
            {
              mostraPrezzi ?
              <>
              </>
              :
              <>
                <FontAwesomeIcon icon={["fas", "asterisk"]} color="red" size="sm" />{' '}<small>Devi essere registrato per vedere i prezzi!</small>
              </>
            } */}


            <div className="container mt-2">
              <Link
                className="btn btn-success btn-block"
                to={{
                  pathname: "/completa-ordine",
                  state: {
                    carrello: carrello,
                    costoConsegna: DELIVERY_PRICE,
                    prezzoFinale: prezzoFinale,
                  },
                }}
              >
                <FontAwesomeIcon icon="thumbs-up" /> COMPLETA ORDINE
              </Link>
            </div>
          </div>
        </>
      );
    }
  };

  const goToCompletaOrdine = () => {
    navigation.navigate("completa-ordine", {
      itemId: 86,
    });
  };

  /*
  exit={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  initial={{ opacity: 0 }}
  */

  return (
    <>
      
      {disegnaElementiCarrello()}

      {
        carrello.length === 0 ?
        <div className="alert alert-info" role="alert">
          <FontAwesomeIcon icon="info-circle" className="fa-2x" color="green" /> Ti invitiamo a consultare l'<a href="/giorni-consegne" className="alert-link">elenco</a> completo dei comuni in cui consegnamo e a scoprire per quali giornate della settimana la spesa te la consegnamo gratuitamente per il comune di tuo interesse!
      Qualora decidessi di ricevere la merce in un giorno diverso da quello da noi previsto, dovrai effettuare un ordine comprensivo di minimo <b>{MIN_PRODUCTS_IN_CART}</b> prodotti per poter concludere l'ordine stesso la cui consegna, in tal caso, avrà un costo di <b>{DELIVERY_PRICE}</b> euro.
          <p>Ricorda anche che, solamente con un account valido e da loggato, potrai visionare i prezzi dei nostri prodotti.</p>
        </div>
        :
        <></>
      }

        {carrello.length > 0 ? (
          <motion.div
          exit={{ x: -300 }}
          animate={{ x: 0 }}
          initial={{ x: -300 }}
          id="bottoni-ordine-corrente"
          className="mx-2"
          style={{
            position: "fixed",
            bottom: 10,
            zIndex: 1000,
          }}
        >
          <button
          className="btn btn-success btn-lg rounded-pill"
          type="button"
          onClick={scrollTop}
        >
          <FontAwesomeIcon className="fa-2x" icon="cart-arrow-down" />{' '} 
          {mostraPrezzi ? <span>{prezzoFinaleCalcolato}</span> : <FontAwesomeIcon icon={["fas", "asterisk"]} color="white" size="sm" />}
          {' '}€
        </button>
        <button
            className="btn btn-danger btn-lg ml-2 rounded-pill"
            type="button"
            onClick={onClearCart}
          >
            <FontAwesomeIcon className="fa-2x" icon="shopping-cart" />{' '}Svuota
        </button>
        </motion.div>
        ) : (
          <></>
        )}
      
    </>
  );
}
