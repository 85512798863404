import React from 'react';


export default function(props) {

    

    return (
        <div className="jumbotron-fluid my-4 text-center d-flex flex-column align-items-center">
            <h1 className="font-weight-bold">{props.titolo.toUpperCase()}</h1>
            <div className="lead" style={{ borderStyle: "solid", borderWidth: "0px 0px 0.1px 0px", borderColor: "darkgrey", textAlign: "center", width: "70%" }}>{props.sottotitolo}</div>
        </div>
    )
}