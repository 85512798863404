import React, { useState, useEffect, createContext } from "react";
import firebase from "../components/Firebase";
const genericFunction = require("../genericFunctions");

export const ParametersContext = createContext();

export const ParametersProvider = (props) => {
  const [parameters, setParameters] = useState([]);//useState(localStorage.getItem('parameters') ? JSON.parse(localStorage.getItem('parameters')) : []);

  useEffect(() => {
    
    debugger;
    let lastUpdateMin = 0;

     if(localStorage.getItem('stellaLastUpdateP')) {
         lastUpdateMin = genericFunction.diff_minutes(new Date(), new Date(JSON.parse(localStorage.getItem('stellaLastUpdateP'))));
     }

    // ogni 60 min. refresho da db
    if(parameters.length === 0 || lastUpdateMin > 60) {
      
      firebase
      .firestore()
      .collection("/parameters")
      .onSnapshot((querySnapshot) => {
        let listaParameters = [];
        querySnapshot.forEach(function (doc) {
          listaParameters.push(doc.data());
        });

        setParameters(listaParameters);
        localStorage.setItem('parameters', JSON.stringify(listaParameters));
        localStorage.setItem('stellaLastUpdateP', JSON.stringify(new Date()));
        console.log("db p new");
      });
    }
    else {
      console.log("db p old");
    }

  }, []);

  return (
    <ParametersContext.Provider value={[parameters, setParameters]}>
      {props.children}
    </ParametersContext.Provider>
  );
};
