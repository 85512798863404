import React from "react";
import firebase from "./Firebase";

import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const paths = [
  {
    "path": "/giorni-consegne",
    "value": "CONSEGNE",
    "admin": false
  },
  {
    "path": "/shop",
    "value": "SHOP",
    "admin": false
  },
  {
    "path": "/gestione-catalogo",
    "value": "GEST. CATALOGO",
    "admin": true
  },
  {
    "path": "/gestione-ordini",
    "value": "GEST. ORDINI",
    "admin": true
  },
  {
    "path": "/stampa-ordini",
    "value": "STAMPA ORDINI",
    "admin": true
  },
  {
    "path": "/giorni-consegne-edit",
    "value": "GEST. CONSEGNE",
    "admin": true
  },
  {
    "path": "/utenti",
    "value": "GEST. UTENTI",
    "admin": true
  }
];

  
export default function ({ user, authentication }) {

  const location = useLocation();

  const disegnaBriciole = () => {

    return (
      <>
      {
        paths.map( (p) => {
          
            if(p.admin) {
              if(authentication.isAuth && authentication.isAdmin) {
                return (
                  <li key={p.path} className={location.pathname === p.path ? "nav-item active" : "nav-item"}>
                <a className="nav-link" href={p.path}>
                <span className="h6">{p.value}</span>
                </a>
              </li>
                )
              }
            }
            else {
              return (
                <li key={p.path} className={location.pathname === p.path ? "nav-item active" : "nav-item"}>
                          <a className="nav-link" href={p.path}>
                          <span className="h5">{p.value}</span>
                          </a>
                        </li>
              )
            }
        })
      }
      </>
    )

  }

  /*
  useEffect(() => {
    //debugger;
    firebase.auth().onAuthStateChanged((user) => {
      //debugger;
      if (user) {
        console.log("user is signed in");

        const userData = JSON.parse(localStorage.getItem("user"));

        if (!userData) {
          // recupero info personali
          mdw.callPostApi("getUserInfo", { uid: user.uid }).then((data) => {
            console.log("Info utente recuperate", data);
            localStorage.setItem("user", JSON.stringify(data));
            setUser(data);
          });
        } else {
          console.log("info utente già presenti", userData);
          setUser(userData);
        }
      } else {
        console.log("user is NOT signed in");
        localStorage.removeItem("user");
        setUser(null);
      }
    });
  }, []);
  

  useEffect(() => {
    if (user !== 0) {
      setDisegnaComponente(true);
    }
  }, [user]);
  */

  const onLogout = () => {
    debugger;
    firebase.auth().signOut().then( () => {
      window.location.reload();
    });
  };

  /*
  const disegna = () => {
    if (user) {
      return (
        <>
          <a
            type="button"
            className="btn btn-outline-light btn-sm mr-2"
            href="/profilo"
          >
            {user.email}
          </a>
          <button type="button" className="btn btn-danger" onClick={onLogout}>
            Logout
          </button>
        </>
      );
    } else {
      return (
        <a type="button" className="btn btn-success" href="/login">
          Login
        </a>
      );
    }
    
  };
  */

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <a className="navbar-brand" href="/">
          <span className="font-weight-bold" style={{ color: "red" }}>STELLAP{' '}</span><FontAwesomeIcon className="fa-1x" icon={["fas", "truck"]} />{' '}ORTO A CASA
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="navbarNav"
          //style={{ justifyContent: "space-between" }}
        >
          <div>
            <ul className="navbar-nav align-items-center">
              {
                disegnaBriciole()
              }
            </ul>
          </div>

          

          <div id="user-info" className="d-flex justify-content-center">
            {user ? (
              <motion.div
                exit={{ y: -150 }}
                animate={{ y: 0 }}
                initial={{ y: -150 }}
              >
                <a className="btn btn-outline-light btn-sm" href="/profilo" role="button">
                  <FontAwesomeIcon icon={["fas", "user-edit"]} />{' '}{user.email}
                </a>
                <button type="button" className="btn btn-danger btn-sm ml-2" onClick={onLogout}><FontAwesomeIcon icon={["fas", "sign-out-alt"]} />{' '}Logout</button>
              </motion.div>
            ) : authentication.initializing ? (
              <></>
            ) : (
              <motion.div
                exit={{ y: -300 }}
                animate={{ y: 0 }}
                initial={{ y: -300 }}
              >
                <a className="btn btn-success" href="/login" role="button">
                  <FontAwesomeIcon icon={["fas", "sign-in-alt"]} />{' '}Login
                </a>
              </motion.div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}
