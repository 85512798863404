import React, { useState, useEffect, useContext } from "react";
import Spinner from "../components/Spinner";
import CardErrore from "../components/CardErrore";
import { Redirect } from "react-router-dom";
import CardTitolo from "../components/CardTitolo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "../components/Firebase";
import CardProdottoGestione from "../components/CardProdottoGestione";
import CardProdottoNuovo from "../components/CardProdottoNuovo";
import { DatabaseContext } from "../components/DatabaseContext";

export default function () {
  const [stellaDb, setStellaDb] = useContext(DatabaseContext);
  const [listaProdotti, setListaProdotti] = useState([]);
  const [listaProdottiBck, setListaProdottiBck] = useState([]);
  const [prodottoSpecifico, setProdottoSpecifico] = useState("");
  const [loading, setLoading] = useState(true);
  const [paginaInErrore, setPaginaInErrore] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection("/products")
      .onSnapshot((querySnapshot) => {
        //debugger;
        let lista = [];
        
        setProdottoSpecifico("");
        //debugger;
        querySnapshot.forEach((doc) => {
          debugger;
          lista.push(doc.data());
        });

        const listaOrdinata = lista.sort(function(a, b) {
          var nameA = a.name.toUpperCase(); // ignora maiuscole e minuscole
          var nameB = b.name.toUpperCase(); // ignora maiuscole e minuscole
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
      
        // i nomi devono essere uguali
        return 0;
      });

        setListaProdotti(listaOrdinata);
        setListaProdottiBck(listaOrdinata);
        setLoading(false);
      });
  }, []);

  useEffect( () => {

    //debugger;
    if(prodottoSpecifico === "") {
      setListaProdotti(listaProdottiBck);
    }
    else {
      setListaProdotti(listaProdottiBck.filter(pd => pd.id === prodottoSpecifico));
    }

  }, [prodottoSpecifico]);

  

  const disegnaSelectProdotti = () => {

    // ordinamento per nome
  const prodotti = listaProdotti.sort(function(a, b) {
    var nameA = a.name.toUpperCase(); // ignora maiuscole e minuscole
    var nameB = b.name.toUpperCase(); // ignora maiuscole e minuscole
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

  // i nomi devono essere uguali
  return 0;
});

    return (
      <select className="form-control" id="prodotti" value={prodottoSpecifico} onChange={(e) => { setProdottoSpecifico(e.currentTarget.value) }}>
        <option value="">
          Tutti ({stellaDb.prodotti.length})
        </option>

        {prodotti.map((prodotto) => (
          <option key={prodotto.id} value={prodotto.id}>
            {prodotto.name}
          </option>
        ))}
      </select>
    );

  }

  return (
    <>
      <CardTitolo
        titolo="GESTIONE CATALOGO"
        sottotitolo="Modifica il tuo catalogo a tuo piacimento"
      />

      <>
        {loading ? (
          <Spinner testo="Caricamento in corso" />
        ) : (
          <>
            {paginaInErrore ? (
              <CardErrore titolo="Oh no" sottotitolo="C'è stato un problema" />
            ) : (
              <div className="container">
                

                <div className="container">
                  <button
                    className="btn btn-light btn-block"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <FontAwesomeIcon icon="folder-plus" size="1x" />{' '}AGGIUNGI NUOVO PRODOTTO
                  </button>

                  <div className="collapse" id="collapseExample">
                    <CardProdottoNuovo />
                  </div>
                </div>

                <div className="container my-4">
                  <div className="form-group">
                    <label className="text-nowrap">Cerca prodotto</label>
                    
                    <select className="form-control" id="prodotti" value={prodottoSpecifico} onChange={(e) => { setProdottoSpecifico(e.currentTarget.value) }}>
                      <option value="">
                        Tutti ({listaProdottiBck.length})
                      </option>

                      {listaProdottiBck.map((prodotto) => (
                        <option key={prodotto.id} value={prodotto.id}>
                          {prodotto.name}
                        </option>
                      ))}
                    </select>

                  </div>
                </div>

                <div className="my-4">
                  
                    {listaProdotti ? (
                      listaProdotti.map((prodotto) => (
                        <CardProdottoGestione
                          key={prodotto.id}
                          prodotto={prodotto}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  
                </div>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

/*

<li className="list-group-item">
                    <div className="container">
                      <form>
                        <div className="form row">
                          <div className="col">
                            <div className="form-group">
                              <label>Nome</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Nome"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label>Descrizione</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Descrizione"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form row">
                          <div className="col">
                            <div className="form-group">
                              <label>Categoria</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Categoria"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label>Sotto categoria</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Sotto categoria"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form row">
                          <div className="col">
                            <div className="form-group">
                              <label>Prezzo</label>
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Prezzo"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label>Unità</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Unità"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form row align-items-center">
                          <div className="col">
                            <div className="container d-flex justify-content-center">
                              <img
                                className="img-thumbnail"
                                src={dummyUrl}
                                style={{ width: 150 }}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label>File Immagine</label>
                              <input
                                type="file"
                                className="form-control-file"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                                <button type="button" className="btn btn-primary btn-lg">Modifica</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </li>

*/
