import React, { useState, useEffect, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import {Redirect} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as mdw from "../middleware";
import Spinner from "./Spinner";
import CardErrore from "./CardErrore";
import firebase from "../components/Firebase";
import {DatabaseContext} from '../components/DatabaseContext';
const genericFunction = require("../genericFunctions");

export default function () {
  const [stellaDb, setStellaDb] = useContext(DatabaseContext);
  //const [listaComuni, setListaComuni] = useState([]);
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [telefono, setTelefono] = useState("");
  const [civico, setCivico] = useState(0);
  const [indirizzo, setIndirizzo] = useState("");
  const [comune, setComune] = useState(-1);
  const [nominativo, setNominativo] = useState("");
  const [loading, setLoading] = useState(true);
  const [paginaInErrore, setPaginaInErrore] = useState(false);
  const [messaggioErrore, setMessaggioErrore] = useState(null);

  /*
  const recuperaComuni = () => {
    //debugger;
    if (sessionStorage.comuni) {
      setListaComuni(JSON.parse(sessionStorage.comuni));
      setLoading(false);
    } else {
      mdw
        .callGetApi("getComuni")
        .then((data) => {
          debugger;
          if (data) {
            console.log("Comuni recuperati", data);
            setListaComuni(data);
            setLoading(false);
            sessionStorage.setItem("comuni", JSON.stringify(data));
          } else {
            console.log("Errore nel recupero dei comuni");
            setPaginaInErrore(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setPaginaInErrore(true);
        });
    }
  };
  */

  useEffect(() => {
    //recuperaComuni();

    if(stellaDb.comuni && stellaDb.comuni.length > 0) {
      setLoading(false);
    }

  }, [stellaDb.comuni]);

  if (loading) {
    return <Spinner />;
  }

  if (paginaInErrore) {
    return <CardErrore titolo="Oh no" sottotitolo="C'è stato un problema" />;
  }

  const disegnaSelectComuni = () => {
    return (
      <select className="form-control" id="comuni" value={comune} onChange={(e) => { setComune(e.currentTarget.value) }}>
        <option disabled value={-1}>
          Scegli un Comune
        </option>

        {stellaDb.comuni.map((comune) => (
          <option key={comune.id} value={comune.id}>
            {comune.nome}
          </option>
        ))}
      </select>
    );
  };


const avviaRegistrazione = (utente) => {
  debugger;
  setMessaggioErrore(null);
  setLoading(true);

      mdw
        .callPostApi("aggiungiUtente", utente)
        .then((data) => {
          debugger;
          if(data.esito) {
            firebase.auth().signInWithCustomToken(data.token).then( () => {
              return (
                <Redirect to="/" />
              )
            })
            .catch( err => {
              console.log("errore login");
            });
          }
          else {
            setMessaggioErrore(data.error.message);
          }

          setLoading(false);
        })
        .catch((error) => {
          genericFunction.writeLog('AGGIUNGI_UTENTE', false, error.message, utente);
          setLoading(false);
          setPaginaInErrore(true);
        });
}

  const onSubmit = (e) => {
    e.preventDefault();

    const nuovoUtente = {
      name: nome.toUpperCase(),
      surname: cognome.toUpperCase(),
      phone: telefono,
      email: email,
      password: password,
      address: indirizzo,
      streetNumber: civico,
      place: comune,
      intercom: nominativo.toUpperCase()
  };
  console.log(nuovoUtente);

  avviaRegistrazione(nuovoUtente);
  };

  const disegnaFormDesktop = () => {
    return (
      <form onSubmit={onSubmit}>
        <div className="card" style={{ flex: 1 }}>
          <div className="card-header">Dati Personali</div>
          <div className="card-body">
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Nome</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Paolo"
                    value={nome}
                    onChange={(e) => { setNome(e.currentTarget.value) }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Cognome</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Stella"
                    value={cognome}
                    onChange={(e) => { setCognome(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Telefono <small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="3491234567"
                    required
                    value={telefono}
                    onChange={(e) => { setTelefono(e.currentTarget.value) }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Email <small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="paolo@stella.com"
                    required
                    value={email}
                    onChange={(e) => { setEmail(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6">
                <div className="form-group">
                  <label className="text-nowrap">Password <small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder=""
                    required
                    minLength={6}
                    maxLength={10}
                    value={password}
                    onChange={(e) => { setPassword(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-4" style={{ flex: 1 }}>
          <div className="card-header">Dati Ordini</div>
          <div className="card-body">
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Indirizzo Abitazione</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Via Roma"
                    value={indirizzo}
                    onChange={(e) => { setIndirizzo(e.currentTarget.value) }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="text-nowrap">Civico</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="22"
                    value={civico}
                    onChange={(e) => { setCivico(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Comune</label>
                  {disegnaSelectComuni()}
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Nominativo Citofono</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Stella"
                    value={nominativo}
                    onChange={(e) => { setNominativo(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <p>
                Abbiamo bisogno di questi dati per poterti permettere di fruire
                del nostro catalogo nel migliore dei modi.
              </p>
              <p>
                I tuoi ordini saranno più veloci e avrai visione di tutti i
                prezzi nel nostro Shop.
              </p>
            </div>

            


          </div>
        </div>

        {
          messaggioErrore ? 

          <div className="container">
            <p style={{ fontWeight: "bold", color: "red" }}>{messaggioErrore}</p>
          </div>

          
            :
            <></>
          }

        <div className="form-row p-3">
          <button type="submit" className="btn btn-block mt-2 btn-success">
            <FontAwesomeIcon icon={["fas", "user-plus"]} />{' '}REGISTRATI
          </button>
        </div>
      </form>
    );
  };

  const disegnaFormMobile = () => {
    return (
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="card-header">Dati Personali</div>
          <div className="card-body">
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Nome</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Paolo"
                    value={nome}
                    onChange={(e) => { setNome(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Cognome</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Stella"
                    value={cognome}
                    onChange={(e) => { setCognome(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Telefono <small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="3491234567"
                    required
                    value={telefono}
                    onChange={(e) => { setTelefono(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Email <small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="paolo@stella.com"
                    required
                    value={email}
                    onChange={(e) => { setEmail(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Password <small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder=""
                    required
                    minLength={6}
                    maxLength={10}
                    value={password}
                    onChange={(e) => { setPassword(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-header">Dati Ordini</div>
          <div className="card-body">
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Indirizzo Abitazione</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Via Roma"
                    value={indirizzo}
                    onChange={(e) => { setIndirizzo(e.currentTarget.value) }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="text-nowrap">Civico</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="22"
                    value={civico}
                    onChange={(e) => { setCivico(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Comune</label>
                  {disegnaSelectComuni()}
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label className="text-nowrap">Nominativo Citofono</label>
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Stella"
                    value={nominativo}
                    onChange={(e) => { setNominativo(e.currentTarget.value) }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <p>
                Abbiamo bisogno di questi dati per poterti permettere di fruire
                del nostro catalogo nel migliore dei modi.
              </p>
              <p>
                I tuoi ordini saranno più veloci e avrai visione di tutti i
                prezzi nel nostro Shop.
              </p>
            </div>
          </div>
        </div>

        {
          messaggioErrore ? 

          <div className="container">
            <p style={{ fontWeight: "bold", color: "red" }}>{messaggioErrore}</p>
          </div>
            :
            <></>
          }

        <div className="form-row p-3">
          <button type="submit" className="btn btn-block mt-2 btn-success">
            <FontAwesomeIcon icon={["fas", "user-plus"]} />{' '}REGISTRATI
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="container">
      <BrowserView>{disegnaFormDesktop()}</BrowserView>

      <MobileView>{disegnaFormMobile()}</MobileView>

      <div className="container">
        <p>
          Torna al{" "}
          <a className="btn btn-link p-0" href="/login" role="button">
            Login
          </a>
          !
        </p>
      </div>

      {/*
   <div className="card">
        <img style={{ height: "20vh", objectFit: "cover" }} src="/images/login-image1.jpg" className="card-img-top" alt="immagine login 1"></img>
        <div className="card-body">
          <h4 className="card-title">DATI PERSONALI</h4>
          
   <form className="d-flex flex-wrap">
            
              <div className="form-group" style={stileGroups}>
                <label className="text-nowrap">Nome</label>
                <input className="form-control" type="text" placeholder="Default input" />
              </div>
              <div className="form-group" style={stileGroups}>
                <label className="text-nowrap">Nome</label>
                <input className="form-control" type="text" placeholder="Default input" />
              </div>
              <div className="form-group" style={stileGroups}>
                <label className="text-nowrap">Nome</label>
                <input className="form-control" type="text" placeholder="Default input" />
              </div>
              <div className="form-group" style={stileGroups}>
                <label className="text-nowrap">Nome</label>
                <input className="form-control" type="text" placeholder="Default input" />
              </div>
              <div className="form-group" style={stileGroups}>
              <label className="text-nowrap">Nome</label>
                <input className="form-control" type="text" placeholder="Default input" />
              </div>
          
            
          </form>
  </div>
      </div>
  
  */}
    </div>
  );
}
