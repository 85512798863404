import React from 'react';


export default function(props) {

    
    return (
        <div className="jumbotron-fluid my-3 text-center d-flex flex-column align-items-center">
            <h5 className="font-weight-bold">{props.titolo.toUpperCase()}</h5>
            <div className="" style={{ borderStyle: "solid", borderWidth: "0px 0px 0.1px 0px", borderColor: "darkgrey", textAlign: "center", width: "45%" }}>{props.sottotitolo}</div>
        </div>
    )
}