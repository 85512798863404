import React from "react";
const genericFunction = require("../genericFunctions");


export default function ({listaComuni}) {
  
  if (listaComuni.length > 0) {
    return (
      <div className="table-responsive">
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col">Comune</th>
                  <th scope="col">Giorno di consegna gratuito</th>
                </tr>
              </thead>
              <tbody>
                {listaComuni.map((comune, i) => (
                  <tr key={i}>
                    <td className="font-weight-bold">{comune.nome}</td>
                    <td>{genericFunction.getNomeGiornoByNumero(comune.giornoConsegna).toUpperCase()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
    );
  }
}
