import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_FIREBASE_APPID,
    measurementId:process.env.REACT_APP_FIREBASE_FIREBASE_MEASUREMENTID
  };


//const pol = firebase.initializeApp(config);
firebase.initializeApp(config);

firebase.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
          console.log("1");
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
          console.log("2");
      }
  });
// Subsequent queries will use persistence, if it was enabled successfully

  export default firebase;


/*
  class Firebase {
    constructor() {
      app.initializeApp(config)
      this.auth = app.auth()
      this.db = app.firestore()
      this.functions = app.functions()
    }
  
    login(email, password) {
      return this.auth.signInWithEmailAndPassword(email, password)
    }
  
    logout() {
      return this.auth.signOut()
    }
  
    async register(name, email, password) {
      await this.auth.createUserWithEmailAndPassword(email, password)
      return this.auth.currentUser.updateProfile({
        displayName: name
      })
    }
  
    isInitialized() {
      return new Promise(resolve => {
        this.auth.onAuthStateChanged(resolve)
      })
    }
  
    getCurrentUsername() {
      return this.auth.currentUser && this.auth.currentUser.displayName
    }
  }
  
  export default new Firebase()
*/
