import React, {useState} from 'react';
import CardTitolo from '../components/CardTitolo';
import Spinner from '../components/Spinner';
import RegistrazioneForm from '../components/RegistrazioneForm';

export default function() {

    const [loading, setLoading] = useState(false);

    return (
        <>
            <CardTitolo
                titolo="REGISTRAZIONE"
                sottotitolo="Registrati subito e godi di tutti i vantaggi di avere un account!"
            />

            <>
                {
                    loading ? 
                    <Spinner testo="Caricamento in corso" />
                    :
                    <RegistrazioneForm />
                }
            </>


        </>
    )
}