import React, { useState, useEffect } from "react";
import Spinner from "../components/Spinner";
import CardTitolo from "../components/CardTitolo";
import firebase from "../components/Firebase";
import CardErrore from "../components/CardErrore";
import TabellaOrdiniGiornata from '../components/TabellaOrdiniGiornata';
import { data } from "jquery";

export default function () {
  const [listaOrdini, setListaOrdini] = useState(null);
  const [listaUtenti, setListaUtenti] = useState([]);
  const [listaComuni, setListaComuni] = useState([]);
  const [giorniConsegne, setGiorniConsegne] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modVisualizzazione, setModVisualizzazione] = useState(0);

  useEffect(() => {
    recuperaComuni();
    recuperaUtenti();
    recuperaOrdini();
  }, []);

  useEffect(() => {
    if (
      listaComuni.length > 0 &&
      listaUtenti.length > 0 &&
      listaOrdini !== null && listaOrdini.length > 0
    ) {
      setLoading(false);
    }
    else if(listaOrdini !== null && listaOrdini.length === 0) {
      //debugger;
      setLoading(false);
    }
  }, [listaComuni, listaOrdini, listaUtenti]);

  const recuperaComuni = () => {
    //debugger;
    firebase
      .firestore()
      .collection("/places")
      .get()
      .then((querySnapshot) => {
        let lista = [];
        querySnapshot.forEach(function (doc) {
          lista.push(doc.data());
        });

        setListaComuni(lista);
      });
  };

  const recuperaUtenti = () => {
    //debugger;
    firebase
      .firestore()
      .collection("/users")
      .get()
      .then((querySnapshot) => {
        let lista = [];
        querySnapshot.forEach(function (doc) {
          lista.push(doc.data());
        });

        setListaUtenti(lista);
      });
  };

  const recuperaOrdini = () => {
    firebase
      .firestore()
      .collection("/orders")
      .onSnapshot((querySnapshot) => {
        let lista = [];

        querySnapshot.forEach((doc) => {
          lista.push(doc.data());
          //debugger;
        });

        console.log("ordini", lista);
        //debugger;

        let listaGiorni = [];
        for (let ord of lista) {
          //debugger;
          if (!listaGiorni.includes(ord.deliveryDay.toDate().getTime())) {
            listaGiorni.push(ord.deliveryDay.toDate().getTime());
          }
        }

        // ordino in base alle date di consegna previste
        listaGiorni.sort(function (a, b) {
          return new Date(a) - new Date(b);
        });

        // escludo tutti gli ordini con date precedenti ad oggi
        const dataOggi = new Date();
        dataOggi.setHours(0);
        dataOggi.setSeconds(0);
        dataOggi.setMinutes(0);
        dataOggi.setMilliseconds(0);
        listaGiorni = listaGiorni.filter(gg => new Date(gg) >= dataOggi);

        setGiorniConsegne(listaGiorni);
        setListaOrdini(lista);
      });
  };

  
  if (loading) {
    return (
      <>
        <CardTitolo
          titolo="Stampa Ordini"
          sottotitolo="Visione semplificata di tutti gli ordini"
        />

        <Spinner testo="Caricamento ordini in corso" />
      </>
    );
  }

  return (
    <>
      <CardTitolo
        titolo="Stampa Ordini"
        sottotitolo="Visione semplificata di tutti gli ordini"
      />

      <div className="container-fluid">

        {
          listaOrdini.length > 0 ?
          <>
           <div id="radioVisualizzazione" className="text-center">
          
          <div className="custom-control custom-radio custom-control-inline">
            <input type="radio" id="customRadioInline1" name="customRadioInline1" value={0} className="custom-control-input" checked={ modVisualizzazione === 0 } onChange={(e) => setModVisualizzazione(parseInt(e.currentTarget.value))} />
            <label className="custom-control-label" htmlFor="customRadioInline1">Visualizzazione Giri Consegne</label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input type="radio" id="customRadioInline2" name="customRadioInline1" value={1} className="custom-control-input" checked={ modVisualizzazione === 1 } onChange={(e) => setModVisualizzazione(parseInt(e.currentTarget.value))} />
            <label className="custom-control-label" htmlFor="customRadioInline2">Visualizzazione Dettaglio Ordini</label>
          </div>
  
  
          </div>
  
          {giorniConsegne.map((giornoConsegna, i) =>
            //disegnaTabellaOrdiniGiornata(giornoConsegna)
            <TabellaOrdiniGiornata key={i}
                                   giornoConsegna={giornoConsegna} 
                                   listaOrdini={listaOrdini} 
                                   listaComuni={listaComuni}
                                   listaUtenti={listaUtenti}
                                   modVisualizzazione={modVisualizzazione} />
          )}
          </>
          :
          <CardErrore
            titolo="Nessun ordine"
            sottotitolo="Non ci sono ordini pervenuti"
          />
        }

      </div>
    </>
  );
}
