import React from "react";

export default function () {
  const backgroundUrl = "../images/logo-satispy.png";

  return (
//  {   <div
//       className="jumbotron jumbotron-fluid d-flex"
//       style={{
//         backgroundColor: "#FF2033",
//         backgroundImage: "url(" + backgroundUrl + ")",
//         backgroundSize: "contain",
//         backgroundRepeat: "space",
//       }}
//     >
//       <div className="container">
//         <div className="d-flex justify-content-start align-items-center">
//           <div className="rounded-circle">
//             <img
//               src="../images/satispy-qr.png"
//               className="mr-2"
//               style={{ width: "7rem" }}
//             />
//           </div>

//           <div
//             className="p-4 rounded-lg"
//             style={{ backgroundColor: "#FFFFFF", opacity: 0.9 }}
//           >
//             <h3 className="font-weight-bold">Comodità di pagamento</h3>
//             <p className="lead">
//               Paga in fase di consegna oppure paga direttamente con{" "}
//               <span className="font-weight-bold">Satispy</span>! Inquadra il
//               nostro QR code
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>}

<div className="jumbotron jumbotron-fluid my-4" style={{
        backgroundColor: "#FF4136",
        backgroundImage: "url(" + backgroundUrl + ")",
        backgroundSize: "contain",
        backgroundRepeat: "space",
      }}>
    
    <div className="container d-flex flex-column align-items-center">
    
    <div className="p-3 rounded">
    <h1 className="display-4 font-weight-bold">Pagamento Smart</h1>
      <p className="lead">Paga in fase di consegna oppure paga direttamente con <span className="font-weight-bold">Satispay</span>! Inquadra il nostro QR code</p>
    </div>
    <div className="rounded">
    <img src="../images/satispy-qr.png" className="" style={{ width: "10rem" }} />
    </div>
      
    </div>
  </div>
  );
}
