import React, { useEffect, useState, useContext } from "react";
import * as mdw from "../middleware";
import CardTitolo from "../components/CardTitolo";
import CardSottotitolo from "../components/CardSottotitolo";
import { BrowserView, MobileView } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../components/Spinner";
import cookie from "react-cookies";
import firebase from "../components/Firebase";
import { DatabaseContext } from "../components/DatabaseContext";



export default function () {
  const [stellaDb, setStellaDb] = useContext(DatabaseContext);
  const [user, setUser] = useState(null);
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [telefono, setTelefono] = useState(0);
  const [indirizzo, setIndirizzo] = useState("");
  const [civico, setCivico] = useState(-1);
  const [nominativo, setNominativo] = useState("");
  const [comune, setComune] = useState(-1);
  const [email, setEmail] = useState("");
  const [buttonModificaDisabled, setButtonModificaDisabled] = useState(true);
  //const [listaComuni, setListaComuni] = useState(sessionStorage.comuni);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //debugger;
    if (user === null) {
      try {
        setUser(cookie.load("stella_user"));
        //recuperaComuni();
        setLoading(false);
      } catch (error) {
        console.log("errore recupero info utente", error);
      }
    }
  }, []);

  useEffect(() => {
    //debugger;
    if (user !== null) {
      setNome(user.name);
      setCognome(user.surname);
      setTelefono(user.phone);
      setIndirizzo(user.address);
      setEmail(user.email);
      setComune(user.place);
      setNominativo(user.intercom);
      setCivico(user.streetNumber);
      setButtonModificaDisabled(true);
    }
  }, [user]);

  useEffect(() => {
    //debugger;

    if (
      user !== null &&
      (nome !== user.name ||
        cognome !== user.surname ||
        telefono !== user.phone ||
        indirizzo !== user.address ||
        nominativo !== user.intercom ||
        email !== user.email ||
        parseInt(comune) !== user.place ||
        parseInt(civico) !== user.streetNumber)
    ) {
      setButtonModificaDisabled(false);
    } else {
      setButtonModificaDisabled(true);
    }
  }, [nome, cognome, telefono, indirizzo, civico, nominativo, comune, email]);

  /*
  const recuperaComuni = () => {
    debugger;

    if (!listaComuni) {
      // recupero info personali
      mdw.callGetApi("getComuni").then((data) => {
        console.log("comuni recuperati", data);
        setListaComuni(data);
        sessionStorage.comuni = JSON.stringify(data);
      });
    } else {
      setListaComuni(JSON.parse(sessionStorage.comuni));
    }
  };
  */

  const modificaDati = () => {
    setLoading(true);

    const datiModificati = {
      uid: user.uid,
      email: email,
      name: nome.toUpperCase(),
      surname: cognome.toUpperCase(),
      phone: parseInt(telefono),
      address: indirizzo,
      streetNumber: parseInt(civico),
      place: parseInt(comune),
      intercom: nominativo.toUpperCase(),
    };

    console.log(datiModificati);

    mdw.callPostApi("aggiornaUtente", datiModificati).then((data) => {
      //debugger;
      if (data) {
        console.log("dati aggiornati", data);
        setLoading(false);
        // cancello cookie autenticazione
        cookie.save("stella_user", datiModificati);
        //localStorage.setItem("user", JSON.stringify(datiModificati));
        //window.location.reload();
      } else {
        console.log("errore aggiornamento profilo");
        setLoading(false);
      }
    });
  };

  const eliminaAccount = () => {
    setLoading(true);

    var user = firebase.auth().currentUser;
    debugger;

    firebase
      .auth()
      .signOut()
      .then(() => {
        mdw
          .callPostApi("eliminaUtente", { uid: user.uid })
          .then((data) => {
            debugger;
            if (data) {
              console.log("Utente rimosso");
              window.location.reload();
            } else {
              console.log("Utente NON rimosso");
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log("Utente NON rimosso");
            setLoading(false);
          });
      });
  };

  const disegnaCardAmministrazione = () => {
    if (user.admin) {
      return (
        <div className="container" style={{ marginTop: "5rem" }}>

          <CardSottotitolo
            titolo="SEZIONE DI AMMINISTRAZIONE"
            sottotitolo="Fai le modifiche al tuo Shop"
          />

              <ul className="list-group list-group-horizontal justify-content-center flex-wrap">
                <li className="list-group-item border-0">
                  <a
                    className="btn btn-warning btn-lg"
                    href="/gestione-catalogo"
                    role="button"
                  >
                    <FontAwesomeIcon icon={["fas", "shopping-basket"]} />{" "}
                    GEST. CATALOGO
                  </a>
                </li>
                <li className="list-group-item border-0">
                  <a
                    className="btn btn-danger btn-lg"
                    href="/gestione-ordini"
                    role="button"
                  >
                    <FontAwesomeIcon icon={["fas", "money-check-alt"]} />{" "}
                    GEST. ORDINI
                  </a>
                </li>
                <li className="list-group-item border-0">
                  <a
                    className="btn btn-info btn-lg"
                    href="/stampa-ordini"
                    role="button"
                  >
                    <FontAwesomeIcon icon={["fas", "print"]} /> STAMPA ORDINI
                  </a>
                </li>
                <li className="list-group-item border-0">
                  <a
                    className="btn btn-secondary btn-lg"
                    href="/giorni-consegne-edit"
                    role="button"
                  >
                    <FontAwesomeIcon icon={["fas", "calendar-day"]} /> GIORNI CONSEGNE
                  </a>
                </li>
                <li className="list-group-item border-0">
                  <a
                    className="btn btn-primary btn-lg"
                    href="/utenti"
                    role="button"
                  >
                    <FontAwesomeIcon icon={["fas", "user"]} /> UTENTI
                  </a>
                </li>
              </ul>
            
          
        </div>
      );
    }
  };

  if (loading) {
    return (
      <>
        <CardTitolo
          titolo="DATI PERSONALI"
          sottotitolo="Gestisci il tuo account, inserisci le informazioni base per i tuoi futuri ordini!"
        />
        <Spinner testo="Caricamento in corso" />
      </>
    );
  }

  if (user) {
    return (
      <div className="container">
        <CardTitolo
          titolo="DATI PERSONALI"
          sottotitolo="Gestisci il tuo account, inserisci le informazioni base per i tuoi futuri ordini!"
        />

        <div
          className="modal fade"
          id="modalEliminaAccount"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalEliminaAccountLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalEliminaAccountLabel">
                  Conferma rimozione Account
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Sei sicuro di voler rimuovere definitivamente il tuo account?
                L'operazione sarà irreversibile.
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={eliminaAccount}
                >
                  Si
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <p>
            Se vuoi, puoi modificare/aggiornare i tuoi dati. Puoi anche decidere
            di lasciarci cancellando completamente il tuo account.
          </p>

          
            <MobileView>


                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="font-weight-bold">Nome</label>
                    <input
                      className="form-control"
                      type="text"
                      value={nome}
                      onChange={(e) => {
                        setNome(e.currentTarget.value);
                      }}
                    />
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col">
                    <div className="form-group">
                      <label className="font-weight-bold">Cognome</label>
                    <input
                      className="form-control"
                      type="text"
                      value={cognome}
                      onChange={(e) => {
                        setCognome(e.currentTarget.value);
                      }}
                    />
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col">
                    <div className="form-group">
                      <label className="font-weight-bold">Telefono</label>
                    <input
                      className="form-control"
                      type="text"
                      value={telefono}
                      onChange={(e) => {
                        setTelefono(e.currentTarget.value);
                      }}
                    />
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col">
                    <div className="form-group">
                      <label className="font-weight-bold">Email</label>
                    <input
                      className="form-control"
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.currentTarget.value);
                      }}
                    />
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col">
                    <div className="form-group">
                      <label className="font-weight-bold">Indirizzo</label>
                    <input
                      className="form-control"
                      type="text"
                      value={indirizzo}
                      onChange={(e) => {
                        setIndirizzo(e.currentTarget.value);
                      }}
                    />
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col">
                    <div className="form-group">
                      <label className="font-weight-bold">Civico</label>
                    <input
                      className="form-control"
                      type="text"
                      value={civico}
                      onChange={(e) => {
                        setCivico(e.currentTarget.value);
                      }}
                    />
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col">
                    <div className="form-group">
                      <label className="font-weight-bold">Citofono</label>
                    <input
                      className="form-control"
                      type="text"
                      value={nominativo}
                      onChange={(e) => {
                        setNominativo(e.currentTarget.value);
                      }}
                    />
                    </div>
                  </div>
                </div>

              
                <div className="row">
                  <div className="col">
                  <div className="form-group">
                      <label className="font-weight-bold">Comune</label>
                      <select
                      className="form-control"
                      value={comune}
                      onChange={(e) => {
                        setComune(e.currentTarget.value);
                      }}
                    >
                      <option value={-1} disabled>
                        Scegli un comune
                      </option>

                      {stellaDb.comuni ? (
                        stellaDb.comuni.map((comune) => (
                          <option key={comune.id} value={comune.id}>
                            {comune.nome}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                    </div>
                  </div>
                  
                </div>
              
            

            </MobileView>

            <BrowserView>
            <div className="container">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">Id</span>
                  </div>
                  <div className="col text-right">
                    <small className="text-muted">{user.uid}</small>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">Nome</span>
                  </div>
                  <div className="col text-right">
                    <input
                      className="form-control"
                      type="text"
                      value={nome}
                      onChange={(e) => {
                        setNome(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">Cognome</span>
                  </div>
                  <div className="col text-right">
                    <input
                      className="form-control"
                      type="text"
                      value={cognome}
                      onChange={(e) => {
                        setCognome(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">Telefono</span>
                  </div>
                  <div className="col text-right">
                    <input
                      className="form-control"
                      type="number"
                      value={telefono}
                      onChange={(e) => {
                        setTelefono(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">Email</span>
                  </div>
                  <div className="col text-right">
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">Indirizzo</span>
                  </div>
                  <div className="col text-right">
                    <input
                      className="form-control"
                      type="text"
                      value={indirizzo}
                      onChange={(e) => {
                        setIndirizzo(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">Civico</span>
                  </div>
                  <div className="col text-right">
                    <input
                      className="form-control"
                      type="number"
                      value={civico}
                      onChange={(e) => {
                        setCivico(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">
                      Nominativo Citofono
                    </span>
                  </div>
                  <div className="col text-right">
                    <input
                      className="form-control"
                      type="text"
                      value={nominativo}
                      onChange={(e) => {
                        setNominativo(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="row align-items-baseline">
                  <div className="col text-left">
                    <span className="font-weight-bold">Comune</span>
                  </div>
                  <div className="col text-right">
                    <select
                      className="form-control"
                      value={comune}
                      onChange={(e) => {
                        setComune(e.currentTarget.value);
                      }}
                    >
                      <option value={-1} disabled>
                        Scegli un comune
                      </option>

                      {stellaDb.comuni ? (
                        stellaDb.comuni.map((comune) => (
                          <option key={comune.id} value={comune.id}>
                            {comune.nome}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                </div>
              </li>
            </ul>
</div>
            </BrowserView>

            
          

          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              type="button"
              className="btn btn-primary mx-2"
              disabled={buttonModificaDisabled}
              onClick={modificaDati}
            >
              <FontAwesomeIcon icon={["fas", "user-edit"]} /> MODIFICA ACCOUNT
            </button>
            <button
              type="button"
              disabled={user.admin}
              className="btn btn-danger mx-2"
              data-toggle="modal"
              data-target="#modalEliminaAccount"
            >
              <FontAwesomeIcon icon={["fas", "user-slash"]} /> ELIMINA ACCOUNT
            </button>
          </div>
        </div>

        {disegnaCardAmministrazione()}
      </div>
    );
  } else {
    return (
      <CardTitolo
        titolo="DATI PERSONALI"
        sottotitolo="Gestisci il tuo account, inserisci le informazioni base per i tuoi futuri ordini!"
      />
    );
  }
}
