import React, { useState, useEffect } from "react";
import CardTitolo from "../components/CardTitolo";
import CardSottotitolo from "../components/CardSottotitolo";
import firebase from "../components/Firebase";
import Spinner from "../components/Spinner";
import CardInfoOrdine from "../components/CardInfoOrdine";
import CardErrore from "../components/CardErrore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const genericFunction = require("../genericFunctions");

const getColoreAccordionByGiorno = (numeroGiorno) => {
  switch (numeroGiorno) {
    case 1:
      return "text-white bg-gradient-primary";
    case 2:
      return "text-white bg-gradient-secondary";
    case 3:
      return "text-white bg-gradient-success";
    case 4:
      return "text-white bg-gradient-info";
    case 5:
      return "bg-light";
    default:
      return "";
  }
};

export default function () {
  const [listaOrdini, setListaOrdini] = useState(null);
  const [listaComuni, setListaComuni] = useState([]);
  const [listaUtenti, setListaUtenti] = useState([]);
  const [giorniConsegne, setGiorniConsegne] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    recuperaComuni();
    recuperaUtenti();
    recuperaOrdini();
  }, []);

  const recuperaOrdini = () => {
    firebase
      .firestore()
      .collection("/orders")
      .onSnapshot((querySnapshot) => {
        let lista = [];

        querySnapshot.forEach((doc) => {
          lista.push(doc.data());
          //debugger;
        });

        console.log("ordini", lista);
        debugger;

        let listaGiorni = [];
        for (let ord of lista) {
          //debugger;
          if (!listaGiorni.includes(ord.deliveryDay.toDate().getTime())) {
            listaGiorni.push(ord.deliveryDay.toDate().getTime());
          }
        }

        // ordino in base alle date di consegna previste
        listaGiorni.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a) - new Date(b);
        });

        // escludo tutti gli ordini con date precedenti ad oggi
        const dataOggi = new Date();
        dataOggi.setHours(0);
        dataOggi.setSeconds(0);
        dataOggi.setMinutes(0);
        dataOggi.setMilliseconds(0);
        listaGiorni = listaGiorni.filter(gg => new Date(gg) >= dataOggi);

        setGiorniConsegne(listaGiorni);
        setListaOrdini(lista);
        //setLoading(false);
      });
  };

  const recuperaComuni = () => {
    //debugger;
    firebase
      .firestore()
      .collection("/places")
      .get()
      .then((querySnapshot) => {
        let lista = [];
        querySnapshot.forEach(function (doc) {
          lista.push(doc.data());
        });

        setListaComuni(lista);
      });
  };

  const recuperaUtenti = () => {
    //debugger;
    firebase
      .firestore()
      .collection("/users")
      .get()
      .then((querySnapshot) => {
        let lista = [];
        querySnapshot.forEach(function (doc) {
          lista.push(doc.data());
        });

        setListaUtenti(lista);
      });
  };

  useEffect(() => {
    if (
      listaComuni.length > 0 &&
      listaUtenti.length > 0 &&
      listaOrdini !== null && listaOrdini.length > 0
    ) {
      setLoading(false);
    }
    else if(listaOrdini !== null && listaOrdini.length === 0) {
      //debugger;
      setLoading(false);
    }
  }, [listaComuni, listaOrdini, listaUtenti]);

  const calcolaCarrelloGiornata = (ordiniGiornata) => {
    let carrelloGiornata = [];

    for (let ordine of ordiniGiornata) {
      for (let prodotto of ordine.cart) {
        let index = carrelloGiornata.findIndex((el) => el.id === prodotto.id);

        if (index !== -1) {
          //debugger;
          // prodotto gia presente, sommo la quantità
          carrelloGiornata[index].quantity =
            carrelloGiornata[index].quantity + prodotto.quantity;
        } else {
          // prodotto ancora non presente, aggiungo
          let prodotto1 = { ...prodotto };
          carrelloGiornata.push(prodotto1);
        }
      }
    }

    return carrelloGiornata;
  };

  const disegnaOrdiniGiornata = (giornoConsegna) => {
    const data = new Date(giornoConsegna);
    const giorno = genericFunction.getNomeGiornoByNumero(data.getDay());
    const ordiniGiornata = listaOrdini.filter(
      (el) => el.deliveryDay.toDate().getTime() === data.getTime()
    );
    const titoloAccordion = giorno + ' ' + data.toLocaleDateString() + ' (Totale: ' + ordiniGiornata.length + ')';
    const coloreAccordion = getColoreAccordionByGiorno(data.getDay());

    const carrelloGiornata = calcolaCarrelloGiornata(ordiniGiornata);

    return (
      <div className="container-giorno-consegna my-4" key={giornoConsegna}>
        <div className="accordion" id={"macro-accordion-" + giornoConsegna}>
          <div className="card">
            <div
              className={"card-header " + coloreAccordion}
              id={"headingOne-" + giornoConsegna}
              role="button"
              data-toggle="collapse"
              data-target={"#collapseOne-" + giornoConsegna}
              aria-expanded="true"
              aria-controls={"collapseOne-" + giornoConsegna}
            >
              {/* {<h2>
              <button className="btn btn-lg btn-block text-center" type="button" data-toggle="collapse" data-target={"#collapseOne-" + giornoConsegna} aria-expanded="true" aria-controls={"collapseOne-" + giornoConsegna}>
                <FontAwesomeIcon icon={["fas", "calendar-day"]} />{' '}{titoloAccordion}
              </button>
            </h2>} */}
              
                <FontAwesomeIcon icon={["fas", "calendar-day"]} />{' '}
                {titoloAccordion}
              
            </div>
            <div
              id={"collapseOne-" + giornoConsegna}
              className="collapse show"
              aria-labelledby={"headingOne-" + giornoConsegna}
              data-parent={"#macro-accordion-" + giornoConsegna}
            >
              <div className="card-body">

              <CardSottotitolo
                titolo="TOTALE ORDINI GIORNATA"
                sottotitolo="Prodotti da preparare per la consegna"
              />


              <div className="table-responsive">
                          <table className="table table-sm table-bordered table-striped">
                            <thead className="thead-dark">
                              <tr>
                                {/* {<th scope="col">Id</th>} */}
                                <th scope="col">Prodotto</th>
                                <th scope="col">Unità</th>
                                <th scope="col">Quantità</th>
                              </tr>
                            </thead>
                            <tbody>
                              {carrelloGiornata.map((prodotto, i) => (
                                <tr key={prodotto.id}>
                                  {/* {<td><small className="">{prodotto.id}</small></td>} */}
                                  <td>{prodotto.name}</td>
                                  <td>{prodotto.unit}</td>
                                  <td>{prodotto.quantity}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>





             

                    
                    <div
                          className="accordion"
                          id={"accordion-" + giornoConsegna}
                        >
                          {ordiniGiornata.map((ordine, i) => (
                            <div className="card" key={ordine.id}>
                              <div className="card-header" id={ordine.id}>
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-outline-info btn-sm btn-block text-left"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={"#" + "collapse" + ordine.id}
                                    aria-expanded="true"
                                    aria-controls={"#" + "collapse" + ordine.id}
                                  >
                                    {ordine.completed ? (
                                      <FontAwesomeIcon
                                        icon={["fas", "circle"]}
                                        color="green"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={["fas", "circle"]}
                                        color="red"
                                      />
                                    )}{" "}
                                    <span className="font-weight-bold">{ordine.address.intercom}</span>{' '}<small># {ordine.id}</small>
                                  </button>
                                </h2>
                              </div>
                              <div
                                id={"collapse" + ordine.id}
                                className="collapse show"
                                aria-labelledby={ordine.id}
                                data-parent={"#accordion-" + giornoConsegna}
                              >
                                <div className="card-body">
                                  <CardInfoOrdine
                                    ordine={ordine}
                                    comuni={listaComuni}
                                    utenti={listaUtenti}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <>
        <CardTitolo
          titolo="GESTIONE ORDINI"
          sottotitolo="Gestisci gli ordini ricevuti e organizzati"
        />

        <Spinner testo="Caricamento ordini in corso" />
      </>
    );
  }

  return (
    <>
      <CardTitolo
        titolo="GESTIONE ORDINI"
        sottotitolo="Gestisci gli ordini ricevuti e quelli passati"
      />

      <div className="container-fluid">
        
        {
          listaOrdini.length > 0 ?
          <>
            {giorniConsegne.map((giornoConsegna) =>
              disegnaOrdiniGiornata(giornoConsegna)
            )}
          </>
          :
          <CardErrore
            titolo="Nessun ordine"
            sottotitolo="Non ci sono ordini pervenuti"
          />
        }
        
        
        

       
      </div>
    </>
  );
}
