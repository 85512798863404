import React from "react";

export default function () {
  return (
    <div className="jumbotron jumbotron-fluid my-4">
      <div className="container">
        <h1 className="display-4 font-weight-bold">Sei un nuovo cliente?</h1>
        <p className="lead">
          Ci sono tre modi per ordinare da noi:{" "}
          <span className="font-weight-bold">chiamaci</span>, scrivici un
          messaggio
          <img alt="WhatsApp" className="img-fluid" src="/images/wa-logo.svg" style={{ width: "2rem" }} />
          al
          numero{' '}
          <span className="font-weight-bold">
            <a
              className="text-dark"
              href="whatsapp://send?text=Ciao voglio ordinare...&phone=+393389194603"
            >
              3389194603
            </a>
          </span>{' '}
          oppure vai nella sezione{' '}
          <span className="font-weight-bold"><a href="/shop" className="text-dark">Shop</a></span> e fai la tua spesa
          online!
        </p>
      </div>
    </div>
  );
}
