import React, {useState} from 'react';
import firebase from "../components/Firebase";

export default function({ comune }) {

    const [giornoConsegna, setGiornoConsegna] = useState(comune.giornoConsegna);

    const cambiaGiornoConsegna = (obj) => {

        setGiornoConsegna(obj.value);

        firebase.firestore().collection("/places").where('id','==', parseInt(comune.id)).limit(1).get().then( querySnapshot => {
            const ref = querySnapshot.docs[0].ref;
            ref.update({ 'giornoConsegna': parseInt(obj.value) });

            console.log("Cambiato il giorno di consegna del comune " + comune.nome + " in " + obj.value);
        })
        .catch( error => {
            console.log("Errore aggiornamento comune giorno di consegna");
        });
    }

    return (
            <tr>
                <td className="font-weight-bold">{comune.nome}</td>
                <td>
                    <select className="form-control" data-place={comune.id}  value={giornoConsegna} onChange={(e) => {cambiaGiornoConsegna(e.currentTarget)}}>
                        <option value="1">LUNEDI</option>
                        <option value="2">MARTEDI</option>
                        <option value="3">MERCOLEDI</option>
                        <option value="4">GIOVEDI</option>
                        <option value="5">VENERDI</option>
                    </select>
                </td>
            </tr>
    )
}