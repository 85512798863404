import React from "react";

import BannerSatispy from "../components/BannerSatispy";
import BannerNuovoCliente from "../components/BannerNuovoCliente";
import BannerAuguri from "../components/BannerAuguri";
import Carosello from "../components/Carosello";
import Pierpaolo from "../components/Pierpaolo";
import CardTitolo from "../components/CardTitolo";
import CardOrdineFinalizzato from "../components/CardOrdineFinalizzato";
import HelmetPage from "../components/HelmetPage";
import { motion } from "framer-motion";
import BannerTuliparty from "../components/BannerTuliparty";
const genericFunction = require("../genericFunctions");

export default function () {
  return (
    <motion.div
      className=""
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <HelmetPage page="home" />
      <Carosello />
      {/* <BannerAuguri /> */}
      {/* <BannerTuliparty /> */}
      <BannerNuovoCliente />
      <BannerSatispy />

      <Pierpaolo
        image="p1"
        position="left"
        text="Nel 1898 nacque mio nonno, coltivatore di ortaggi in una cascina in Barriera di Milano, espropriata poi nel 1929 per poter costruire sui suoi terreni l’ospedale San Giovanni Bosco."
      />
      <Pierpaolo
        image="p5"
        position="right"
        text="Da lì, il trasloco a Cascina Le Vallette dove nacquero cinque figli."
      />
      <Pierpaolo
        image="p3"
        position="left"
        text="Il secondo genito, Giuseppe anche lui, ha continuato il lavoro del padre e, mentre i figli Giuseppina, Germano, Giovanni e Vilma trapiantavano cavoli e brassicacee, il 18 luglio 1977, vengo al mondo io, Pierpaolo."
      />
      <Pierpaolo
        image="p2"
        position="right"
        text="Anche Cascina Le Vallette fu espropriata negli anni '60 per fare spazio all’omonimo quartiere lasciando però ancora in piedi una porzione di fabbricato, ancora oggi esistente."
      />
      <Pierpaolo
        image="p9"
        position="left"
        text="L’azienda si sposta a Savonera e Druento affittando terreni limitrofi fino al 2007, quando decido di comprare un terreno al confine dei comuni di Collegno e Pianezza per coltivare ortaggi e ricostruire una cascina nuova."
      />
    </motion.div>
  );
}
