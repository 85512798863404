import React, {createRef} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from "react-to-print";

import TabellaStampaSingoloOrdine from './TabellaStampaSingoloOrdine';
const genericFunction = require("../genericFunctions");
const jsonFasceConsegna = require("../utils/fasceConsegna.json");

export default function({giornoConsegna, listaOrdini, listaComuni, listaUtenti, modVisualizzazione}) {

    const RifTabellaGiri = createRef();
    
    const data = new Date(giornoConsegna);
    //const giorno = genericFunction.getNomeGiornoByNumero(data.getDay());
    const ordiniGiornata = listaOrdini.filter((el) => el.deliveryDay.toDate().getTime() === data.getTime()).sort(function(a, b) {return a.timeSlot - b.timeSlot;});

    //debugger;

    const stampaTabellaGiro = useReactToPrint(
      {
        content: () => RifTabellaGiri.current,
        copyStyles: true,
      }
    );

    return (
        
        <div className="table-responsive my-4" key={giornoConsegna}>

          <table
            ref={RifTabellaGiri}
            className="table table-striped table-bordered table-lg"
          >
            <thead className="bg-gradient-dark text-white">
              <tr className="">
                <th scope="row" colSpan="10">
                  <div className="d-flex justify-content-between align-items-center">
                        <span style={{ fontSize: "x-large", fontWeight: 500 }}>

                          {
                            modVisualizzazione === 0 ?
                              <FontAwesomeIcon icon={["fas", "truck-loading"]} />
                            :
                              <FontAwesomeIcon icon={["fas", "calendar-day"]} />
                          }

                          
                          
                          
                          
                          { ' '}CONSEGNE DEL {data.toLocaleDateString()}
                        </span>
                    

                        <button
                      type="button"
                      className="btn btn-success"
                      onClick={stampaTabellaGiro}
                    >
                      <FontAwesomeIcon icon={["fas", "print"]} />
                      {
                        modVisualizzazione === 0 ?
                        "STAMPA GIRO"
                        :
                        "STAMPA GIORNATA"
                      }
                    </button>



                    
                  </div>
                </th>
              </tr>
            </thead>

            {
              modVisualizzazione === 0 ?
              <>
              <thead className="">
              <tr className="table-active text-nowrap">
                <th scope="col">Stato</th>
                <th scope="col">Orario</th>
                <th scope="col">Citofono</th>
                <th scope="col">Indirizzo</th>
                <th scope="col">Comune</th>
                <th scope="col">Pagamento</th>
                <th scope="col">Totale (€)</th>
                <th scope="col">Consegna (€)</th>
                <th scope="col">Note cliente</th>
                <th scope="col">Telefono</th>
              </tr>
            </thead>
            <tbody>
              {ordiniGiornata.map((ordine, i) => (
                
                <tr key={i}>
                  <td>
                    {ordine.completed ? (
                      <FontAwesomeIcon icon={["fas", "circle"]} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={["fas", "circle"]} color="red" />
                    )}
                  </td>
                  <td>{jsonFasceConsegna.fasce.filter(f => f.id === ordine.timeSlot)[0].value}</td>
                  <td>{ordine.address.intercom}</td>
                  <td className="text-nowrap">
                    {ordine.address.address}, {ordine.address.streetNumber}
                  </td>
                  <td className="text-nowrap">
                    {
                      listaComuni.filter(
                        (el) => el.id === parseInt(ordine.address.place)
                      )[0].nome
                    }
                  </td>
                  <td>{ordine.payment === 0 ? "Satispy" : "Consegna"}</td>
                    <td>{ordine.price}</td>
                    <td>{ordine.deliveryPrice}</td>
                  <td className="text-wrap">
                    <i><small>{ordine.note}</small></i>
                  </td>
                  <td>
                      {
                          listaUtenti.filter((el) => el.uid === ordine.user)[0]?.phone
                      }
                  </td>
                  
                </tr>
                
              
              ))}
            </tbody>
            </>
              :
              
                <tbody>
                  {
                    ordiniGiornata.map( (ordine) => (
                      <tr key={ordine.id}>
                        <td>
                          <TabellaStampaSingoloOrdine ordine={ordine} />
                        </td>
                        
                      </tr>
                      
                  ))
                  }
                </tbody>

              

                /* {
                ordiniGiornata.map( (ordine) => {
                    {
                      return (
                        
                        <table ref={RifTabellaOrdine} className="table table-striped table-bordered table-sm">

                          <thead>
                            <th scope="row" colSpan="10" className="table-info">
                              
                            <div className="d-flex justify-content-between align-items-center">
                              <span>ORDINE # {' '}{ordine.id}</span>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={stampaTabellaOrdine}
                              >
                                <FontAwesomeIcon icon={["fas", "print"]} /> STAMPA ORDINE
                              </button>
                            </div>
                              
                              
                              
                            </th>
                          </thead>

                          <thead className="bg-active">
                            <tr className="table-active text-nowrap">
                              <th scope="col">Prodotto</th>
                              <th scope="col">Quantità</th>
                            </tr>
                          </thead>

                          <tbody>

                          {ordine.cart.map( (prodotto) => (

                            <tr key={prodotto.id}>
                              <td>{prodotto.name}</td>
                              <td>{prodotto.quantity}{' '}{prodotto.unit}</td>
                            </tr>
                            
                          ))}

                          </tbody>
                        </table>
                      )
                      
                    }
                })
              } */
                
            
            
            }

            
          </table>
        </div>
      
    )
}