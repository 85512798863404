import React, { useState, useEffect, useContext, createRef } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import CardProdottoVendita from "../components/CardProdottoVendita";
import CardRiepilogoOrdine from "../components/CardRiepilogoOrdine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {motion} from 'framer-motion';
import CardErrore from "../components/CardErrore";
import CardInformazione from "../components/CardInformazione";
import Spinner from "../components/Spinner";
import CardTitolo from "../components/CardTitolo";
import { DatabaseContext } from "../components/DatabaseContext";
import { ParametersContext } from "../components/ParametersContext";
import HelmetPage from '../components/HelmetPage';
const genericFunction = require("../genericFunctions");

const jsonCategorie = require("../utils/categorie.json");

export default function () {
  const [stellaDb, setStellaDb] = useContext(DatabaseContext);
  const [PARAMETERS, setParameters] = useContext(ParametersContext);
  const [SHOP_ENABLED, setShopEnabled] = useState(false);
  const [pippo, setPippo] = useState(0);
  const [listaProdotti, setListaProdotti] = useState(stellaDb.prodotti);
  const [isBusy, setIsBusy] = useState(true);
  const [categoriaSelezionata, setCategoriaSelezionata] = useState("-1");
  const [sottocategoriaSelezionata, setSottocategoriaSelezionata] = useState("-1");
  const [carrello, setCarrello] = useState(sessionStorage.carrello ? JSON.parse(sessionStorage.carrello) : []);
  const [isCarrelloClearing, setIsCarrelloClearing] = useState(false);
  //const [scrollY, setScrollY] = useState(window.scrollY);
  // const [ordinamentoNome, setOrdinamentoNome] = useState(0);
  // const [ordinamentoPrezzo, setOrdinamentoPrezzo] = useState(0);
  const [paginaInErrore, setPaginaInErrore] = useState(false);

  const RefGoUp = createRef();

    window.onscroll = () => {
      
      if(RefGoUp.current) {
        if(window.scrollY > (document.documentElement.clientHeight/2)) {
          RefGoUp.current.style.display = "block";
        }
        else {
          RefGoUp.current.style.display = "none";
        }
      }
      
    };

  // useEffect(() => {
  //   window.onscroll = () => {
  //     setScrollY(window.scrollY);
  //   };
  // }, []);
  
  useEffect(() => {
    //debugger;
    if (stellaDb.prodotti.length && PARAMETERS.length) {

      // ordino inizialmente
      let prodottiOrdinati = stellaDb.prodotti.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignora maiuscole e minuscole
        var nameB = b.name.toUpperCase(); // ignora maiuscole e minuscole
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
    
        // i nomi devono essere uguali
        return 0;
      });

      let chiaveParametro = "SHOP_ENABLED";

      if(genericFunction.isLocalhosting())
      {
        chiaveParametro = "SHOP_ENABLED_FOR_TESTING";
      }

      const shopEnabled = PARAMETERS.filter( el => el.key === chiaveParametro)[0].value;
      setShopEnabled(shopEnabled);

      setListaProdotti(prodottiOrdinati);
      gestioneCarrello();
      setIsBusy(false);
    }
  }, [stellaDb.prodotti, PARAMETERS]);


  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // useEffect(() => {

  //   debugger;

  //   let prodottiOrdinati = [];
  //   if(parseInt(ordinamentoNome) === 0) {

  //     prodottiOrdinati = listaProdotti.sort(function(a, b) {
  //       var nameA = a.name.toUpperCase(); // ignora maiuscole e minuscole
  //       var nameB = b.name.toUpperCase(); // ignora maiuscole e minuscole
  //       if (nameA < nameB) {
  //         return -1;
  //       }
  //       if (nameA > nameB) {
  //         return 1;
  //       }
    
  //       // i nomi devono essere uguali
  //       return 0;
  //     });
  //   }
  //   else {
  //     prodottiOrdinati = listaProdotti.sort(function(a, b) {
  //       var nameA = a.name.toUpperCase(); // ignora maiuscole e minuscole
  //       var nameB = b.name.toUpperCase(); // ignora maiuscole e minuscole
  //       if (nameA > nameB) {
  //         return -1;
  //       }
  //       if (nameA < nameB) {
  //         return 1;
  //       }
    
  //       // i nomi devono essere uguali
  //       return 0;
  //     });
  //   }

  //   if(parseInt(ordinamentoPrezzo) === 0) {
  //     prodottiOrdinati = prodottiOrdinati.sort(function(a, b) {
  //       return a.price - b.price;
  //     });
  //   }
  //   else {
  //     prodottiOrdinati = prodottiOrdinati.sort(function(a, b) {
  //       return b.price - a.price;
  //     });
  //   }

  //   setListaProdotti(prodottiOrdinati);

  // }, [ordinamentoNome, ordinamentoPrezzo])



  useEffect(() => {
    //debugger;

    if (isCarrelloClearing) {
      setListaProdotti([]);
    }
  }, [isCarrelloClearing]);

  useEffect(() => {
    //debugger;

    if (isCarrelloClearing && listaProdotti.length === 0) {
      setListaProdotti(stellaDb.prodotti);
      setIsCarrelloClearing(false);
    }
  }, [listaProdotti]);

  useEffect(() => {
    let filtro = stellaDb.prodotti;
    //debugger;
    if (categoriaSelezionata === "-1" && sottocategoriaSelezionata === "-1") {
      // niente
    } else if (
      categoriaSelezionata !== "-1" &&
      sottocategoriaSelezionata !== "-1"
    ) {
      filtro = filtro.filter((e) => {
        return (
          e.category === categoriaSelezionata &&
          e.subcategory === sottocategoriaSelezionata
        );
      });
    } else if (categoriaSelezionata === "-1") {
      filtro = filtro.filter((e) => {
        return e.subcategory === sottocategoriaSelezionata;
      });
    } else {
      filtro = filtro.filter((e) => {
        return e.category === categoriaSelezionata;
      });
    }
    /*
      if (categoriaSelezionata === "-1") {
        filtro = filtro.filter((e) => {
          return (
            e.category !== "" && e.subcategory === sottocategoriaSelezionata
          );
        });
      } else if (sottocategoriaSelezionata === "-1") {
        filtro = filtro.filter((e) => {
          return e.subcategory !== "" && e.category === categoriaSelezionata;
        });
      }
      */

    setListaProdotti(filtro);
  }, [categoriaSelezionata, sottocategoriaSelezionata]);

  /*
  useEffect(() => {
    //debugger;
    calcolaCostiSpedizione();
  }, [finalPrice]);
  */

  const cancellaCarrello = () => {
    // piallo il carrello tutto a 0
    /*
    var newCart = [];
    for (let el of listaProdotti) {
      newCart.push({ id: el.id, quantity: 0 });
    }
    */

    setCarrello([]);
    setPippo(0);
    setIsCarrelloClearing(true);
    //sessionStorage.carrello = JSON.stringify([]);
    //cookie.save('carrello', []);
    //window.location.reload();
  };

  const gestioneCarrello = () => {
    //debugger;
    if (carrello && carrello.filter((el) => el.quantity > 0).length > 0) {
      // non ricomincio il carrello, ma ricalcolo i prezzi
      //calcolaFinalPrice();

      // aggiorno i prodotti nel carrello eventualmente, con il price
      carrello.forEach((element, i) => {
        const prodotto = stellaDb.prodotti.filter(
          (el) => el.id === element.id
        )[0];

        if (prodotto.hasOwnProperty("price")) {
          carrello[i].price = prodotto.price;
        } else {
          delete element.price;
        }
      });

      //sessionStorage.carrello = carrello;
    } else {
      // piallo il carrello tutto a 0
      /*
      var newCart = [];
      for (let el of listaProdotti) {
        newCart.push({ id: el.id, quantity: 0 });
      }
      */
      //setCarrello([]);
      //sessionStorage.carrello = JSON.stringify([]);
      //cookie.save('carrello', []);
    }
  };

  const handleValueCart = (idProdotto, quantita, op) => {
    //debugger;

    let nuovoCarrello = carrello;

    if (quantita === 0) {
      // rimuovo il prodotto se c'è
      const indice = nuovoCarrello.findIndex((el) => el.id === idProdotto);

      if (indice !== -1) {
        nuovoCarrello.splice(indice, 1);
      }
    } else {
      // controllo se esiste gia nel carrello questo prodotto
      if (nuovoCarrello.filter((el) => el.id === idProdotto).length === 1) {
        nuovoCarrello.filter(
          (el) => el.id === idProdotto
        )[0].quantity = quantita;
      } else {
        const prodotto = stellaDb.prodotti.filter(
          (el) => el.id === idProdotto
        )[0];
        prodotto.quantity = quantita;
        nuovoCarrello.push(prodotto);
      }
    }

    let p = pippo + 1;
    setPippo(p);
    setCarrello(nuovoCarrello);
    //sessionStorage.carrello = JSON.stringify(nuovoCarrello);
    //cookie.save('carrello', nuovoCarrello);

    console.log("carrello modificato", nuovoCarrello);

    /*
    let copiaCart = carrello;
    let index = copiaCart.findIndex((p) => p.id === idProdotto);

    // aggiorno la quantità del prodotto
    copiaCart[index].quantity = quantita;

    sessionStorage.carrello = JSON.stringify(copiaCart);

    console.log("carrello modificato", copiaCart);
    //calcolaFinalPrice();

    setCarrello(copiaCart);
    // let p = pippo +1;
    // setPippo(p);
    */
  };

  /*
  const calcolaFinalPrice = () => {
    var prezzo = 0;

    if (isAuth) {
      // calcolo prezzo

      var prezzo = 0;
      // calcolo prezzo finale
      cart.forEach((el, i) => {
        var prodotto = listaProdottiBck.filter((pd) => el.id === pd.id)[0];

        if (prodotto && el.quantity > 0) {
          prezzo += el.quantity * prodotto.price;
        }
      });

      if (prezzo === 0) {
        setModaleOrdine(false);
      }

      setFinalPrice(parseFloat(prezzo));
      sessionStorage.prezzoFinale = Number(prezzo).toFixed(3);
    } else if (cart.filter((el) => el.quantity > 0).length > 0) {
      prezzo = "???";
    }

    setFinalPrice(prezzo);
    sessionStorage.prezzoFinale = prezzo;
  };

  const calcolaCostiSpedizione = () => {
    //debugger;
    if (parseFloat(finalPrice) <= 20) {
      setCostoConsegna(4);
    } else if (finalPrice > 20 && finalPrice <= 40) {
      setCostoConsegna(2);
    } else {
      setCostoConsegna(0);
    }

    sessionStorage.costoConsegna = parseFloat(costoConsegna);
  };
  */

  const disegnaFiltriCategorie = () => {
    return (
      <div className="jumbotron jumbotron-fluid text-center">
        <div className="d-flex flex-column">
          <h2>Categoria</h2>
          <div>
            <ToggleButtonGroup
              className="d-flex flex-wrap align-items-start justify-content-center"
              id="togglegroup-categoria"
              name="categoria"
              defaultValue={categoriaSelezionata}
              onChange={(e) => {
                setCategoriaSelezionata(e);
              }}
            >
              <ToggleButton
                type="radio"
                name="categoria"
                className="m-3 text-nowrap rounded-lg"
                value="-1"
                variant={categoriaSelezionata === "-1" ? "primary" : "light"}
              >
                <FontAwesomeIcon icon={["fas", "border-all"]} />{' '}Listino completo
              </ToggleButton>
              {jsonCategorie.categorie.map((cat) => {

                // se trovo una categoria non appartenente a nessun prodotto evito di disegnarla
                const prodottiConCategoria = stellaDb.prodotti.filter(p => p.category === cat.id).length;
                const disabledToggleBtn = prodottiConCategoria > 0 ? false : true;
                
                  return (
                    
                      <ToggleButton
                    type="radio"
                    name="categoria"
                    disabled={disabledToggleBtn}
                    variant={
                      categoriaSelezionata === cat.id ? "primary" : "light"
                    }
                    className="m-3 text-nowrap rounded-lg"
                    value={cat.id}
                    key={cat.id}
                  >
                    
                    {/* <motion.div whileHover={{
                        scale: 2,
                        transition: { duration: 0.5 },
                      }}
                      whileTap={{ scale: 0.9 }}>
                    {cat.img ? (
                      <>
                        <img
                          className="rounded"
                          style={{ width: "1.5rem" }}
                          src={cat.img}
                        />{" "}
                      </>
                    ) : (
                      <></>
                    )}
                      
                    </motion.div> */}

                    {cat.img ? (
                      <>
                        <img
                          className="rounded"
                          style={{ width: "1.5rem" }}
                          src={cat.img}
                        />{" "}
                      </>
                    ) : (
                      <></>
                    )}


                    {cat.value}
                  </ToggleButton>
                    
                  );
                
              })}
            </ToggleButtonGroup>
          </div>
          <h2 className="mt-4">Sottocategoria</h2>
          <div>
            <ToggleButtonGroup
              className="d-flex flex-wrap align-items-start justify-content-center"
              id="togglegroup-sottocategoria"
              name="sottocategoria"
              defaultValue={sottocategoriaSelezionata}
              onChange={(e) => {
                setSottocategoriaSelezionata(e);
              }}
            >
              <ToggleButton
                type="radio"
                name="sottocategoria"
                className="m-3 text-nowrap rounded-lg"
                value="-1"
                variant={
                  sottocategoriaSelezionata === "-1" ? "primary" : "light"
                }
              >
                <FontAwesomeIcon icon={["fas", "border-all"]} />{' '}Listino completo
              </ToggleButton>
              {jsonCategorie.sottocategorie.map((subcat) => (
                <ToggleButton
                  type="radio"
                  name="sottocategoria"
                  variant={
                    sottocategoriaSelezionata === subcat.id
                      ? "primary"
                      : "light"
                  }
                  className="m-3 text-nowrap rounded-lg"
                  value={subcat.id}
                  key={subcat.id}
                >

                  {/* <motion.div whileHover={{
                        scale: 2,
                        transition: { duration: 0.5 },
                      }}
                      whileTap={{ scale: 0.9 }}>


                  {subcat.img ? (
                    <>
                      <img
                        className="rounded"
                        style={{ width: "1.5rem" }}
                        src={subcat.img}
                      />{" "}
                    </>
                  ) : (
                    <></>
                  )}
                      
                  </motion.div> */}


                  {subcat.img ? (
                    <>
                      <img
                        className="rounded"
                        style={{ width: "1.5rem" }}
                        src={subcat.img}
                      />{" "}
                    </>
                  ) : (
                    <></>
                  )}

                  
                  {subcat.value}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    );
  };

  // const disegnaOrdinamenti = () => {
  //   return (
  //     <div className="jumbotron jumbotron-fluid text-center">
  //       <div className="d-flex flex-column">
  //         <h2>Ordina per...</h2>
  //         <div className="container d-flex flex-row align-items-center justify-content-center">
  //         <div className="form-row flex-fill mx-4">
  //             <div className="col">
  //               <div className="form-group">
  //                 <label className="text-nowrap">Nome</label>
  //                 <select className="form-control" value={ordinamentoNome} onChange={ (e) => setOrdinamentoNome(e.currentTarget.value) }>
  //                   <option value={0}>Dalla A alla Z</option>
  //                   <option value={1}>Dalla Z alla A</option>
  //                 </select>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="form-row flex-fill mx-4">
  //             <div className="col">
  //               <div className="form-group">
  //                 <label className="text-nowrap">Prezzo</label>
  //                 <select className="form-control" value={ordinamentoPrezzo} onChange={ (e) => setOrdinamentoPrezzo(e.currentTarget.value) }>
  //                   <option value={0}>Dal meno caro</option>
  //                   <option value={1}>Dal più caro</option>
  //                 </select>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  if (isBusy) {
    return (
      <>
        <HelmetPage page="shop" />
        <CardTitolo
          titolo="SHOP"
          sottotitolo="Tutti i prodotti di cui hai bisogno"
        />
        <Spinner testo="Caricamento Shop in corso" />
      </>
    );
  }

  if (paginaInErrore) {
    return (
      <>
        <HelmetPage page="shop" />
        <CardTitolo
          titolo="SHOP"
          sottotitolo="Tutti i prodotti di cui hai bisogno"
        />
        <CardErrore
          titolo="Oh no"
          sottotitolo="Si sono verificati degli errori. Ci scusiamo per il disagio."
        />
      </>
    );
  }

  if(SHOP_ENABLED === false) {
    return (
      <>
        <HelmetPage page="shop" />
        <CardTitolo
          titolo="SHOP"
          sottotitolo="Tutti i prodotti di cui hai bisogno"
        />
        <CardErrore
          titolo="Vendita sospesa"
          sottotitolo="Purtroppo in questo momento la vendita dei prodotti è disabilitata. Riprova più tardi."
        />
      </>
    );
  }


  return (
    <>
      <HelmetPage page="shop" />
      <CardTitolo
        titolo="SHOP"
        sottotitolo="Tutti i prodotti di cui hai bisogno"
      />
      <CardRiepilogoOrdine
        carrello={carrello}
        onClearCart={cancellaCarrello}
        pippo={pippo}
      />

      {disegnaFiltriCategorie()}
      {/* {disegnaOrdinamenti()} */}


      <div className="container-prodotti-shop d-flex flex-row flex-wrap align-items-baseline justify-content-center">
        {listaProdotti && listaProdotti.length > 0 ? (
          listaProdotti.map((p, i) => {
            let pc = carrello.filter((el) => el.id === p.id)[0];

            let quantita = pc ? pc.quantity : 0;

            return (
              <CardProdottoVendita
                key={p.id}
                prodotto={p}
                quantita={quantita}
                onValueCartChange={handleValueCart}
              />
            );
          })
        ) : (
          <CardInformazione
            titolo="Ops"
            sottotitolo="Nessun prodotto per questa ricerca"
          />
        )}
      </div>

      <motion.div onClick={scrollTop}
                  ref={RefGoUp}
                  id="fl-to-top"
                  className="rounded"
                  role="button"
                  style={{
                    position: "fixed",
                    //display: scrollY > 300 ? "block" : "none",
                    bottom: 10,
                    zIndex: 1000,
                    //backgroundColor: "grey",
                    //opacity: 0.5,
                    right: 5
                  }}>
        <FontAwesomeIcon size="3x" opacity={0.35} color="grey" icon={["far", "arrow-alt-circle-up"]} />
      </motion.div>

    </>
  );
}
