import axios from 'axios';
const genericFunction = require("./genericFunctions");


export const callPostApi = (methodName, data) => {

    return axios.post(genericFunction.getEndpointApi() + "/" + methodName, JSON.stringify(data), { 
        headers: {
            'Content-Type': 'application/json',
        }
     }).then(res => { return res.data; });
};

export const callGetApi = (methodName, data) => {
    return axios.get(genericFunction.getEndpointApi() + "/" + methodName, JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => { return res.data; });
}

export const callPutApi = (methodName, uri) => {
    return axios.put(genericFunction.getEndpointApi() + "/" + methodName, uri, {
        headers: {
            'Content-Type': 'application/jpeg',
        }
    }).then(res => { return res.data; });
}
