import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function(props) {
    return (
        <div className="jumbotron">
            <h1 className="display-4"><FontAwesomeIcon icon={["fas", "info-circle"]} />{' '}{props.titolo}</h1>
            <p className="lead">{props.sottotitolo}</p>
        </div>
    )
}