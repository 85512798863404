import React, { useState, useEffect } from "react";
import CardTitolo from "../components/CardTitolo";
import Spinner from "../components/Spinner";
import firebase from "../components/Firebase";
import CardGiorniConsegne from '../components/CardGiorniConsegne';
import HelmetPage from '../components/HelmetPage';

export default function () {
  const [listaComuni, setListaComuni] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    recuperaComuni();
  }, []);

  useEffect(() => {
    if (listaComuni.length > 0) {
      setLoading(false);
    }
  }, [listaComuni]);

  const recuperaComuni = () => {
    //debugger;
    firebase
      .firestore()
      .collection("/places")
      .orderBy('nome')
      .get()
      .then((querySnapshot) => {
        let lista = [];
        querySnapshot.forEach(function (doc) {
          lista.push(doc.data());
        });

        setListaComuni(lista);
      });
  };

  if (loading) {
    return (
      <>
        <HelmetPage page="giorni-consegne" />
        <CardTitolo
          titolo="Giorni consegne"
          sottotitolo="Giorni di consegna gratuita"
        />
        <Spinner testo="Caricamento Comuni" />
      </>
    );
  }

  return (
    <>
      <HelmetPage page="giorni-consegne" />
      <CardTitolo
        titolo="Giorni consegne"
        sottotitolo="Giorni di consegna gratuita"
      />

      <div className="container">
        <p>Di seguito trovi l'elenco di tutti i comuni in cui effettuiamo la consegna a domicilio e il relativo giorno di consegna gratuito.
            Qualora decidessi di ricevere la merce in un giorno differente, il tuo ordine avrà un costo di 2 euro.</p>
        <CardGiorniConsegne listaComuni={listaComuni} />
      </div>
    </>
  );
}
