import React, { useEffect, useState } from "react";
import * as mdw from "../middleware";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from './Spinner';
import Resizer from 'react-image-file-resizer';
const jsonCategorie = require("../utils/categorie.json");

export default function () {

    const [aggiungi, setAggiungi] = useState(false);
    const [nome, setNome] = useState("");
    const [descrizione, setDescrizione] = useState("");
    const [prezzo, setPrezzo] = useState(0);
    const [unita, setUnita] = useState(-1);
    const [categoria, setCategoria] = useState(-1);
    const [sottocategoria, setSottocategoria] = useState(-1);
    const [immagine, setImmagine] = useState("");
    const [successo, setSuccesso] = useState(false);
    const [errore, setErrore] = useState(false);
    const [loading, setLoading] = useState(false);

    const FileImageRef = React.createRef();

    useEffect( () => {
        //debugger;
        if (
            nome !== "" &&
            //descrizione !== "" &&
            parseFloat(prezzo) > 0 &&
            unita !== -1 &&
            parseInt(categoria) !== -1 &&
            parseFloat(sottocategoria) !== -1 &&
            immagine !== ""
          ) {
            setAggiungi(true);
          } else {
            setAggiungi(false);
          }

    }, [nome, descrizione, prezzo, unita, categoria, sottocategoria, immagine])


    useEffect( () => {

        setTimeout(() => {
            setSuccesso(false);
            setErrore(false);
            //setModifica(false);
        }, 3500)
    
      }, [successo, errore])

const aggiungiProdotto = () => {

if(FileImageRef.current.files.length === 1) {

  setLoading(true);

    Resizer.imageFileResizer(
        FileImageRef.current.files[0],
        300,
        300,
        'JPEG',
        100,
        0,
        uri => {
            debugger;
            
            let nuovoProdotto = {
                category: parseInt(categoria),
                subcategory: parseInt(sottocategoria),
                name: nome,
                description: descrizione,
                price: parseFloat(prezzo),
                unit: unita
              };
            
              mdw
              .callPutApi("uploadImage", uri)
              .then((data) => {
          
                if (data.esito && data.idImage) {
          
                    nuovoProdotto.imageId = data.idImage;

                    mdw
                    .callPostApi("aggiungiProdotto", nuovoProdotto)
                    .then((data) => {
                    //debugger;
                    if (data) {
                        //props.finish();
                        setSuccesso(true);
                        setNome("");
                        setDescrizione(""); //nome, descrizione, prezzo, unita, categoria, sottocategoria, immagine
                        setPrezzo(0);
                        setUnita(-1);
                        setCategoria(-1);
                        setSottocategoria(-1);
                        setImmagine("");
                        setLoading(false);
                    } else {
                        //errore
                        //props.finish();
                        setErrore(false);
                        setLoading(false);
                    }
                    })
                    .catch((error) => {
                    //errore
                    //props.finish();
                    setErrore(false);
                    setLoading(false);
                    });
                }
              })
              .catch((error) => {
                  // errore
              });
        },
        'blob');
    }
}

if(loading) {
  return (
    <Spinner testo="Aggiunta prodotto" />
  )
}

  return (

    <>

        <div
          className="alert alert-success my-4"
          role="alert"
          style={{ display: successo ? "block" : "none" }}
        >
          Prodotto aggiunto con successo!
        </div>
        <div
          className="alert alert-danger my-4"
          role="alert"
          style={{ display: errore ? "block" : "none" }}
        >
          Errore modifica prodotto!
        </div>

      <div className="card my-4">
        <div className="card-header bg-gradient-success text-white">
          Aggiungi nuovo prodotto
        </div>
        <div className="card-body">
 <form>
        <div className="form row">
          <div className="col">
            <div className="form-group">
              <label>Nome{' '}<small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
              <input className="form-control" type="text" placeholder="Nome" value={nome}
                  onChange={(e) => {
                    setNome(e.currentTarget.value);
                  }} />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Descrizione</label>
              <input
                className="form-control"
                type="text"
                placeholder="Descrizione" value={descrizione}
                onChange={(e) => {
                  setDescrizione(e.currentTarget.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="form row">
          <div className="col">
            <div className="form-group">
              <label>Categoria{' '}<small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
              <select
                  className="form-control"
                  type="text"
                  placeholder="Categoria"
                  value={categoria}
                  onChange={(e) => {
                    setCategoria(e.currentTarget.value);
                  }}
                >
                   <option disabled value={-1}>Scegli una categoria</option>
                  {jsonCategorie.categorie.map((categoria) => (
                    <option value={categoria.id} key={categoria.id}>
                      {categoria.value}
                    </option>
                  ))}
                </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Sotto categoria{' '}<small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
              <select
                  className="form-control"
                  type="text"
                  placeholder="Sotto categoria"
                  value={sottocategoria}
                  onChange={(e) => {
                    setSottocategoria(e.currentTarget.value);
                  }}
                >
                   <option disabled value={-1}>Scegli una sotto categoria</option>
                   {jsonCategorie.sottocategorie.map((subcategoria) => (
                    <option value={subcategoria.id} key={subcategoria.id}>
                      {subcategoria.value}
                    </option>
                  ))}
                </select>
            </div>
          </div>
        </div>
        <div className="form row">
          <div className="col">
            <div className="form-group">
              <label>Prezzo{' '}<small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
              <input
                className="form-control"
                type="number"
                placeholder="Prezzo"
                value={prezzo}
                  onChange={(e) => {
                    setPrezzo(e.currentTarget.value);
                  }}
              />
              {/* <input type="range" min={0} max={100} step={0.5} /> */}
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Unità{' '}<small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
              <select
                  className="form-control"
                  type="text"
                  placeholder="Unità"
                  value={unita}
                  onChange={(e) => {
                    setUnita(e.currentTarget.value);
                  }}
                >
                  <option disabled value={-1}>Scegli una unità</option>
                  <option value="kg">€/kg</option>
                  <option value="mazzo">€/mazzo</option>
                  <option value="mazzetto">€/mazzetto</option>
                  <option value="cadauno">€/cadauno</option>
                  <option value="cestino">cestino</option>
                </select>
            </div>
          </div>
        </div>
        <div className="form row">
          <div className="col">
            <div className="form-group">
              <input type="file"
                    data-browse="Seleziona immagine"
                    className="form-control-file"
                    accept="image/jpeg"
                    value={immagine}
                    onChange={(e) => setImmagine(e.currentTarget.value)}
                    ref={FileImageRef} />
            </div>
          </div>
        </div>
        
      </form>
        </div>
        <div className="card-footer">
        <button type="button" className="btn btn-success btn-block btn-lg" onClick={aggiungiProdotto} disabled={!aggiungi}>
                <FontAwesomeIcon icon="folder-plus" />{' '}AGGIUNGI
              </button>
        </div>
      </div>


     
    </>

  );
  
}
