import React, { useState, useEffect, useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Redirect } from "react-router-dom";
import * as mdw from "../middleware";
import cookie from "react-cookies";
import Spinner from "../components/Spinner";
import CardErrore from "../components/CardErrore";
import CardTitolo from "../components/CardTitolo";
import CardRiepilogoOrdineLight from "../components/CardRiepilogoOrdineLight";
import { DatabaseContext } from "../components/DatabaseContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardOrdineFinalizzato from "../components/CardOrdineFinalizzato";
import { ParametersContext } from "../components/ParametersContext";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
const genericFunction = require("../genericFunctions");
const jsonFasceConsegna = require("../utils/fasceConsegna.json");
registerLocale("it", it);

//const MIN_PRODOTTI = 8;

export default function (props) {
  
  const [PARAMETERS, setParameters] = useContext(ParametersContext);
  const carrello =
    props.location.state !== undefined ? props.location.state.carrello : null;
  const costoConsegna =
    props.location.state !== undefined
      ? props.location.state.costoConsegna
      : null;
  const prezzoFinale =
    props.location.state !== undefined
      ? props.location.state.prezzoFinale
      : null;

  const [stellaDb, setStellaDb] = useContext(DatabaseContext);
  const [MIN_PRODUCTS_IN_CART, setMinProdottiCarrello] = useState();
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [paginaInErrore, setPaginaInErrore] = useState(false);
  //const [listaComuni, setListaComuni] = useState([]);
  const [giornoConsegna, setGiornoConsegna] = useState(null);
  const [giornoConsegnaDisabled, setGiornoConsegnaDisabled] = useState(true);
  const [comuneVal, setComuneVal] = useState("");
  const [comuneObj, setComuneObj] = useState(null);
  const [modalitaPagamento, setModalitaPagamento] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [civico, setCivico] = useState("");
  const [nominativo, setNominativo] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState();
  const [fasciaOraria, setFasciaOraria] = useState("");
  const [note, setNote] = useState("");
  const [ordineFinalizzato, setOrdineFinalizzato] = useState(false);
  const [nuovaPassword, setNuovaPassword] = useState(null);
  const [idOrdine, setIdOrdine] = useState(null);
  const [costoConsegnaCalcolato, setCostoConsegnaCalcolato] = useState(costoConsegna);
  const [inviaOrdineDisabled, setInviaOrdineDisabled] = useState(true);
  const [mostraMessaggioInfoGiornoGratis, setMostraMessaggioInfoGiornoGratis] = useState(false);
  const [mostraMessaggioWarning, setMostraMessaggioWarning] = useState(false);

  useEffect(() => {
    if(PARAMETERS.length) {
      const min = PARAMETERS.filter( el => el.key === "MIN_PRODUCTS_IN_CART")[0].value;
      setMinProdottiCarrello(min);
    }
  }, [PARAMETERS]);

  /*
  const recuperaComuni = () => {
    //debugger;
    if (sessionStorage.comuni) {
      setListaComuni(JSON.parse(sessionStorage.comuni));
      setLoading(false);
    } else {
      mdw
        .callGetApi("getComuni")
        .then((data) => {
          debugger;
          if (data) {
            console.log("Comuni recuperati", data);
            setListaComuni(data);
            setLoading(false);
            sessionStorage.comuni = JSON.stringify(data);
          } else {
            console.log("Errore nel recupero dei comuni");
            setPaginaInErrore(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setPaginaInErrore(true);
        });
    }
  };
  */

  useEffect(() => {
    debugger;
    if (stellaDb.comuni.length > 0) {
      riempiCampiSeLoggato();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    debugger;

    let comuneOggetto = null;
    if (comuneVal !== "") {
      comuneOggetto = stellaDb.comuni.filter((el) => el.id === parseInt(comuneVal))[0];
      setGiornoConsegnaDisabled(false);
      setComuneObj(comuneOggetto);
    } else {
      setGiornoConsegnaDisabled(true);
    }

    // ogni volta che si sceglie un comune, azzero il giorno consegna
    setGiornoConsegna(null);
  }, [comuneVal]);

  const disegnaSelectComuni = () => {
    return (
      <select
        required
        className="form-control"
        id="comuni"
        value={comuneVal}
        onChange={(e) => {
          setComuneVal(e.currentTarget.value);
        }}
      >
        <option disabled value="">
          Scegli un Comune
        </option>

        {stellaDb.comuni.map((comune) => (
          <option key={comune.id} value={comune.id}>
            {comune.nome}
          </option>
        ))}
      </select>
    );
  };

  const riempiCampiSeLoggato = () => {
    if (cookie.load("stella_user")) {
      setIsAuth(true);
      const userData = cookie.load("stella_user");

      setIndirizzo(userData.address);
      setCivico(userData.streetNumber);
      setComuneVal(userData.place);
      setNominativo(userData.intercom);
      setEmail(userData.email);
      setTelefono(userData.phone);
    }
  };

  const onCompletaOrdine = (e) => {
    e.preventDefault();
    debugger;
    if(comuneVal === "" ||
      fasciaOraria === "" ||
      giornoConsegna === null || 
      modalitaPagamento === "") {
      return;
    }

    const ordine = {
      user: {
        uid: isAuth ? cookie.load("stella_user")?.uid : null,
        email: email,
        address: indirizzo.toUpperCase(),
        streetNumber: parseInt(civico),
        place: parseInt(comuneVal),
        intercom: nominativo.toUpperCase(),
        phone: parseInt(telefono),
      },
      order: {
        payment: parseInt(modalitaPagamento),
        deliveryDay: new Date(giornoConsegna),
        note: note.toUpperCase(),
        cart: carrello,
        price: parseFloat(prezzoFinale),
        deliveryPrice: parseInt(costoConsegnaCalcolato),
        timeSlot: parseInt(fasciaOraria),
      },
    };

    console.log("Nuovo ordine", ordine);

    setLoading(true);

    mdw
      .callPostApi("aggiungiOrdine", ordine)
      .then((data) => {
        debugger;
        if (data.esito) {
          setIdOrdine(data.id);
          setNuovaPassword(data.nuovaPassword);
          setOrdineFinalizzato(true);

          // cancello sessionStorage
          sessionStorage.clear();
        } else {
          setPaginaInErrore(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setPaginaInErrore(true);
        setLoading(false);

        genericFunction.writeLog('AGGIUNGI_ORDINE', false, error.message, ordine);
      });
  };

  const onChangeGiornoConsegna = (data) => {
    debugger;
    // recupero giorno consegna selezionato
    const giornoScelto = new Date(data).getDay();
    // recupero il giorno di consegna gratis per il comune selezionato
    const giornoGratis = comuneObj.giornoConsegna;

    if (giornoScelto === giornoGratis) {
      setCostoConsegnaCalcolato(0);
      setMostraMessaggioWarning(false);
      setMostraMessaggioInfoGiornoGratis(false);
    } else {
      setCostoConsegnaCalcolato(costoConsegna);
      setMostraMessaggioWarning(true);
      setMostraMessaggioInfoGiornoGratis(false);
    }

    setGiornoConsegna(data);
  };


  useEffect( () => {

    if(giornoConsegna && costoConsegnaCalcolato !== 0 && carrello.length < MIN_PRODUCTS_IN_CART) {

      setInviaOrdineDisabled(true);
    }
    else {
      setInviaOrdineDisabled(false);
    }

    if(giornoConsegna === null || costoConsegnaCalcolato > 0) {
      setMostraMessaggioInfoGiornoGratis(true);
    }

    if(giornoConsegna === null) {
      setMostraMessaggioWarning(false);
    }

  }, [giornoConsegna, costoConsegnaCalcolato])


  //debugger;
  if (!carrello) {
    debugger;
    return <Redirect to="/shop" />;
  }

  if (loading) {
    return (
      <>
        <CardTitolo
          titolo="INVIA ORDINE"
          sottotitolo="Ti manca pochissimo per inviare il tuo ordine"
        />
        <Spinner />
      </>
    );
  }

  if (ordineFinalizzato) {
    debugger;
    return (
      <>
        <CardTitolo titolo="INVIA ORDINE" sottotitolo="" />
        <CardOrdineFinalizzato
          idOrdine={idOrdine}
          email={email}
          nuovaPassword={nuovaPassword}
        />
      </>
    );
  }

  if (paginaInErrore) {
    return (
      <>
        <CardTitolo titolo="INVIA ORDINE" sottotitolo="" />
        <CardErrore
          titolo="Oh no"
          sottotitolo="Si sono verificati degli errori. Ci scusiamo per il disagio."
        />
      </>
    );
  }

  return (
    <>
      <CardTitolo
        titolo="INVIA ORDINE"
        sottotitolo="Ti manca pochissimo per inviare il tuo ordine"
      />
      <div className="container">
        <CardRiepilogoOrdineLight carrello={carrello} />

        <div className="container my-2">
        <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">COSTO CONSEGNA{' '}</span>
              <span className="font-weight-bold">{costoConsegnaCalcolato}{' '}€</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="font-weight-bold">TOTALE</span>
                  {
                    prezzoFinale === 0 ?
                    <span className="badge badge-pill"><FontAwesomeIcon icon={["fas", "asterisk"]} color="red" size="sm" /></span>
                    :
                    <span className="font-weight-bold">{genericFunction.toFixedPrice(parseFloat(costoConsegnaCalcolato) + parseFloat(prezzoFinale))}{' '}€</span>
                  }
              </li>
            </ul>
        </div>


        <form onSubmit={onCompletaOrdine}>
          <div className="form-row">
            <div className="col">
              <p>
                Hai dimenticato qualcosa? Torna allo{" "}
                <a
                  className="btn btn-primary btn-sm"
                  href="/shop"
                  role="button"
                >
                  <FontAwesomeIcon className="fa-1x" icon="cart-arrow-down" />{' '}SHOP
                </a>
              </p>
            </div>
          </div>
          <div className="form-row">
            <div className="col">
              {isAuth ? (
                <p>
                  Avendo già un account, abbiamo provveduto a riempire i tuoi
                  dati. Modificali a tuo piacimento.
                </p>
              ) : (
                <p>
                  Se non effettui il login o non hai un account, inserendo i
                  dati qui sotto faremo tutto noi per te.
                </p>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>
                  Comune{" "}
                  <small style={{ color: "red", fontWeight: "bold" }}>*</small>
                </label>
                {disegnaSelectComuni()}
              </div>
            </div>

            <div className="col">
              <div className="form-group d-flex flex-column">
                <label>
                  Consegna{" "}
                  <small style={{ color: "red", fontWeight: "bold" }}>*</small>
                </label>
                <DatePicker
                  required
                  disabled={giornoConsegnaDisabled}
                  className="form-control"
                  locale="it"
                  dateFormat="dd/MM/yyyy"
                  minDate={genericFunction.addDays(new Date(), 1)}
                  maxDate={genericFunction.addDays(new Date(), 7)}
                  excludeDates={[new Date()]}
                  filterDate={genericFunction.isWeekday}
                  selected={giornoConsegna}
                  onChange={(date) => onChangeGiornoConsegna(date)}
                  placeholderText="Scegli un giorno di consegna"
                />
              </div>
            </div>
            {/* <div className="col">
              <div className="form-group">
                <label>Consegna (€)</label>
                <input
                  disabled
                  required
                  min={0}
                  className="form-control"
                  type="number"
                  value={costoConsegnaCalcolato}
                ></input>
              </div>
            </div> */}
          </div>

          {comuneObj ? (
            <>
            {
              mostraMessaggioInfoGiornoGratis ?
              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <div className="alert alert-success text-wrap" role="alert">
                      <FontAwesomeIcon icon="info-circle" className="fa-1x" />{" "}
                      Se scegli come giorno di consegna{" "}
                      <b>
                        {genericFunction
                          .getNomeGiornoByNumero(comuneObj.giornoConsegna)
                          .toUpperCase()}
                      </b>{" "}
                      non pagherai il costo della consegna per il comune di{" "}
                      <b>{comuneObj.nome}</b> senza limiti nel numero di
                      prodotti!
                    </div>
                  </div>
                </div>
              </div>
              :
              <></>
            }
              
              {
              mostraMessaggioWarning && (MIN_PRODUCTS_IN_CART - carrello.length) > 0 ? (
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <div
                        className="alert alert-warning text-wrap"
                        role="alert"
                      >
                        <FontAwesomeIcon icon="exclamation-circle" className="fa-1x" />{" "}
                        Con questa scelta il costo di consegna sarà di <b>{costoConsegnaCalcolato}</b>{' '}euro e devi riempire il carrello con almeno <b>{MIN_PRODUCTS_IN_CART}</b>{' '}
                        prodotti diversi. Ti mancano <b>{MIN_PRODUCTS_IN_CART - carrello.length}</b>{' '}prodotti per poter finalizzare questo ordine. Torna allo Shop e fallo subito oppure
                        cambia il giorno di consegna.
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <div className="form-row">
            <div className="col-8">
              <div className="form-group">
                <label>
                  Indirizzo{" "}
                  <small style={{ color: "red", fontWeight: "bold" }}>*</small>
                </label>
                <input
                  required
                  className="form-control text-uppercase"
                  type="text"
                  placeholder="Via Roma"
                  value={indirizzo}
                  onChange={(e) => setIndirizzo(e.currentTarget.value)}
                ></input>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>
                  Civico{" "}
                  <small style={{ color: "red", fontWeight: "bold" }}>*</small>
                </label>
                <input
                  required
                  className="form-control"
                  type="number"
                  placeholder="22"
                  value={civico}
                  onChange={(e) => setCivico(e.currentTarget.value)}
                ></input>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>
                  Nominativo citofono{" "}
                  <small style={{ color: "red", fontWeight: "bold" }}>*</small>
                </label>
                <input
                  required
                  className="form-control text-uppercase"
                  type="text"
                  placeholder="Stella"
                  value={nominativo}
                  onChange={(e) => setNominativo(e.currentTarget.value)}
                ></input>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>
                  Telefono{" "}
                  <small style={{ color: "red", fontWeight: "bold" }}>*</small>
                </label>
                <input
                  required
                  className="form-control"
                  type="number"
                  placeholder="3491234567"
                  value={telefono}
                  onChange={(e) => setTelefono(e.currentTarget.value)}
                ></input>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  placeholder="paolo@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                ></input>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>
                  Tipo di pagamento{" "}
                  <small style={{ color: "red", fontWeight: "bold" }}>*</small>
                </label>
                <select
                  required
                  className="form-control"
                  value={modalitaPagamento}
                  onChange={(e) => setModalitaPagamento(e.currentTarget.value)}
                >
                  <option value="" disabled>
                    Scegli la modalità di pagamento
                  </option>
                  <option value="0">Satispy</option>
                  <option value="1">Alla consegna</option>
                </select>
              </div>
            </div>
          </div>

          {/* {<div className="form-row">
            <div className="col text-center">
              <img style={{ width: "10rem" }} className="img-fluid" src="../images/satispy.png" />
            </div>
          </div>} */}

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Note di consegna</label>
                <textarea
                  className="form-control"
                  placeholder="Scrivi eventuali note da farci sapere"
                  value={note}
                  onChange={(e) => setNote(e.currentTarget.value)}
                  rows="1"
                ></textarea>
              </div>
            </div>
            <div className="col-3">
              <label>Orario{' '}<small style={{ color: "red", fontWeight: "bold" }}>*</small></label>
              <select
                className="form-control"
                required
                type="text"
                placeholder="Fascia oraria"
                value={fasciaOraria}
                onChange={(e) => {
                  setFasciaOraria(e.currentTarget.value);
                }}
              >
                <option disabled value="">
                  Scegli una fascia oraria
                </option>

                {jsonFasceConsegna.fasce.map((fascia) => (
                  <option key={fascia.id} value={fascia.id}>
                    {fascia.value}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-row text-justify">
          <div className="alert alert-info text-wrap" role="alert">
              Cliccando il pulsante qui sotto procederai con l'invio del tuo ordine.
              Riceverai una comunicazione via email (raccomandiamo di inserirla nel form) con tutti
              i dettagli di conferma da parte nostra. Il giorno della consegna
              riceverai una seconda comunicazione con la foto dello scontrino e
              il prezzo finale da pagare.
            </div>
          </div>

          <div className="form-row">
            <button
              type="submit"
              disabled={inviaOrdineDisabled}
              className="btn btn-block btn-lg mt-2 btn-success"
            >
              <FontAwesomeIcon icon="thumbs-up" /> INVIA ORDINE
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
