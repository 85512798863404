import React, { useState, useEffect } from "react";
import CardTitolo from "../components/CardTitolo";
import Spinner from "../components/Spinner";
import firebase from "../components/Firebase";
import CardGiorniConsegne from "../components/CardGiorniConsegne";
import HelmetPage from "../components/HelmetPage";

export default function () {
  const [listaUtenti, setListaUtenti] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    recuperaUtenti();
  }, []);

  useEffect(() => {
    if (listaUtenti.length > 0) {
      setLoading(false);
    }
  }, [listaUtenti]);

  const recuperaUtenti = () => {
    //debugger;
    firebase
      .firestore()
      .collection("/users")
      .get()
      .then((querySnapshot) => {
        let lista = [];
        querySnapshot.forEach(function (doc) {
          lista.push(doc.data());
        });

        setListaUtenti(lista);
      });
  };

  if (loading) {
    return (
      <>
        <CardTitolo
          titolo="Utenti"
          sottotitolo="Gli utenti registrati sul portale"
        />
        <Spinner testo="Caricamento Utenti" />
      </>
    );
  }

  return (
    <>
      <CardTitolo
        titolo="Utenti"
        sottotitolo="Gli utenti registrati sul portale"
      />

      <div className="container">
        <p>
          Di seguito trovi l'elenco di tutti gli utenti che si sono registrati fino ad oggi.
        </p>

        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nome</th>
                <th scope="col">Cognome</th>
                <th scope="col">Telefono</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {
                  listaUtenti.map( (utente, i) => (
                      <tr key={utente.uid}>
                          <td>{i+1}</td>
                          <td>{utente.name}</td>
                          <td>{utente.surname}</td>
                          <td>{utente.phone}</td>
                          <td>{utente.email}</td>
                      </tr>
                  ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
