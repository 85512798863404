import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const genericFunction = require("../genericFunctions");

export default function({carrello}) {

    const [mostraPrezzi, setMostraPrezzi] = useState(false);


    useEffect( () => {

        if (carrello.length > 0 && carrello[0].hasOwnProperty("price")) {
            setMostraPrezzi(true);
          } else {
            setMostraPrezzi(false);
          }

    }, []);


    return (
        <div className="container">
            <h5 className="font-weight-bold">Riepilogo ordine</h5>
            <table className="table table-sm table-bordered table-striped">
              <thead>
                <tr className="table-active text-center">
                  <th scope="col">Prodotto</th>
                  <th scope="col">Quantità</th>

                  {
                    mostraPrezzi ?
                    <th scope="col">Costo Unit.</th>
                    :
                    <></>
                  }

                </tr>
              </thead>
              <tbody>
                {carrello.map((prodotto, i) => (
                  <tr key={i} className="text-center">
                    <td>{prodotto.name}</td>
                    <td>
                      {
                        prodotto.unit === "kg" ?
                        <span>{prodotto.quantity.toFixed(3)}</span>
                        :
                        <span>{prodotto.quantity}</span>
                      }
                    </td>

                    {
                      mostraPrezzi ?
                      <td>
                        {genericFunction.toFixedPrice(prodotto.price)} <small>€/{prodotto.unit}</small>
                      </td>
                      :
                      <></>
                    }
                    
                  </tr>
                ))}
              </tbody>
            </table>
            
        </div>
    )
}