import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {isMobile} from "react-device-detect";

export default function () {

  return (
    <footer
      className="footer container-fluid mt-4"
      style={{
        backgroundImage: 'url("/images/footer/bg-footer.jpg")',
        backgroundColor: "#333",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center top",
        backgroundSize: "cover",
        //position: "fixed",
        bottom: 0,
        padding: 35,
      }}
    >
      <div className={ "container d-flex align-items-center text-white " + (isMobile ? "flex-column" : "flex-row") }>
        <div className="text-center my-4" style={{ flex: 1 }}>
          <h1 className="display-4 text-monospace"><FontAwesomeIcon icon={["fas", "quote-left"]} color="red" />{' '}<span style={{ color: "white" }}>Il meglio vien mangiando</span>{' '}<FontAwesomeIcon icon={["fas", "quote-right"]} color="red" /></h1>
          <br />
          <FontAwesomeIcon icon={["fas", "star"]} size="4x" color="yellow" />
        </div>
        {/* <div id="chi-siamo" className="text-center" style={{ flex: 1 }}>
          <div className="my-2">
            <span className="font-weight-bold">
              CHI SIAMO
            </span>
            <div className="my-1 text-justify">
              <p>Al contrario di quanto si pensi, Lorem Ipsum non è semplicemente una sequenza casuale di caratteri. Risale ad un classico della letteratura
                latina del 45 AC, cosa che lo rende vecchio di 2000 anni. Richard McClintock, professore di latino al Hampden-Sydney College in Virginia,
                ha ricercato una delle più oscure parole latine, consectetur, da un passaggio del Lorem Ipsum e ha scoperto tra i vari testi in cui è citata,
                la fonte da cui è tratto il testo, le sezioni 1.10.32 and 1.10.33 del "de Finibus Bonorum et Malorum" di Cicerone.</p>
            </div>
          </div>
        </div> */}
        <div
          id="footer-infos"
          className="d-flex flex-column align-items-center text-center"
          style={{ flex: 1 }}
        >
          <div className="my-2">
            <span className="font-weight-bold">
              AZIENDA AGRICOLA STELLA PIERPAOLO
            </span>
          </div>
          <div className="my-1">
            <FontAwesomeIcon icon={["fas", "map-marked-alt"]} /> Via Collegno - Pianezza (TO)
          </div>
          <div className="my-1">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2816.720347881928!2d7.544646015774674!3d45.091463766257505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886a5e2ef64623%3A0xc98efe39899e0f26!2sVia%20Collegno%2C%20Citt%C3%A0%20Metropolitana%20di%20Torino!5e0!3m2!1sit!2sit!4v1590908982954!5m2!1sit!2sit" style={{ frameborder: 0, border: 0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
          </div>
          <div className="my-1">
            <FontAwesomeIcon icon={["fas", "envelope"]} />{" "}
            <a
              style={{ color: "white" }}
              href="mailto:stellaportoacasa@gmail.com"
            >
              stellaportoacasa@gmail.com
            </a>
          </div>
          <div className="mt-1">
            <FontAwesomeIcon icon={["fas", "globe-europe"]} /> P.Iva 08191390015
          </div>
          <div className="my-1">
            <FontAwesomeIcon icon={["fas", "mobile"]} /> +39-3389194603
          </div>
          <div className="my-1">
            <FontAwesomeIcon icon={["fab", "instagram"]} />{" "}
            <a
              style={{ color: "white" }}
              target="_blank"
              href="https://www.instagram.com/pierpaolostella"
            >
              pierpaolostella
            </a>
          </div>
          <div className="mt-4">
            <FontAwesomeIcon icon={["fas", "user-secret"]} />{" "}
            <a
              style={{ color: "white" }}
              target="_blank"
              href="https://www.iubenda.com/privacy-policy/49096552/full-legal"
            >
              <small className="text-muted">Privacy Policy</small>
            </a>
          </div>
          <div className="mt-1">
            <FontAwesomeIcon icon={["fas", "cookie-bite"]} />{" "}
            <a
              style={{ color: "white" }}
              target="_blank"
              href="https://www.iubenda.com/privacy-policy/49096552/cookie-policy"
            >
              <small className="text-muted">Cookie Policy</small>
            </a>
          </div>
        </div>
        
      </div>
      <div id="copyright">
        <div className="mt-5 text-center">
            <small className="text-muted">
              <FontAwesomeIcon icon={["far", "copyright"]} /> Copyright 2020{" "}
              <b>
                <a
                  style={{ color: "white" }}
                  href="mailto:paoloscafatidev@gmail.com"
                >
                  PaoloScafatiDev
                </a>
              </b>{" "}
              - All Rights Reserved
            </small>
          </div>
        </div>
    </footer>
  );
}
