import React from "react";

const items = [
  { image: "1", active: false },
  { image: "2", active: false },
  { image: "3", active: false },
  { image: "4", active: false },
  { image: "5", active: true },
  { image: "6", active: false },
  { image: "7", active: false },
  { image: "8", active: false },
  { image: "9", active: false },
  { image: "10", active: false },
];

export default function () {
  const disegnaCaroselloItems = () => {

    return (
        <>
        <ol className="carousel-indicators">

            {
                items.map((item,i) => (
                    <li key={i} data-target="#caroselloStella" data-slide-to={i} className={item.active ? "active" : ""}></li>
                ))
            }

        </ol>
        <div className="carousel-inner">
        {
            items.map((item, i) => (
            
                <div className={item.active ? "carousel-item active" : "carousel-item"} key={i} >
                {/* <img
                    src={"images/carosello/" + item.image}
                    className="d-block w-100"
                    alt={"Immagine Carosello " + i}
                /> */}
                <picture className="">
                    <source srcSet={"images/carosello/" + item.image + ".webp"} type="image/webp" className="w-100" />
                    <source srcSet={"images/carosello/" + item.image + ".jpg"} type="image/jpeg" className="w-100" /> 
                    <img src={"images/carosello/" + item.image + ".jpg"} alt={"Immagine Pierpaolo " + item.image} className="w-100" />
                </picture>
                </div>
            ))
        }
        </div>
        </>
    )
  };

  return (
    <div
      id="caroselloStella"
      className="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="2500"
      data-pause="false"
    >
      {disegnaCaroselloItems()}
      <a
        className="carousel-control-prev"
        href="#caroselloStella"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#caroselloStella"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
}
