import React, {useEffect, useState} from "react";
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function (props) {

    const [testoCaricamento, setTestoCaricamento] = useState(props.testo);

    useEffect( () => {

        if(testoCaricamento === undefined) {
            setTestoCaricamento("Caricamento");
        }

    }, [testoCaricamento])

  return (
    <div style={{ height: "50vh"}} className="d-flex justify-content-center align-items-center flex-column">
      <motion.div
    animate={{ rotate: 360 }}
    transition={{ duration: 2, loop: Infinity }}
  >
        <FontAwesomeIcon icon="truck-moving" className="fa-5x" />
        
      </motion.div>
    <span style={{ fontWeight: 600, fontSize:"large" }}>{testoCaricamento}...</span>
    </div>
  );
}
