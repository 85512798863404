import React, { useState, useEffect } from "react";
import * as mdw from "../middleware";
import Resizer from "react-image-file-resizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from './Spinner';
import firebase from "../components/Firebase";
import { BrowserView, MobileView } from "react-device-detect";

const jsonCategorie = require("../utils/categorie.json");
const urlImmagine =
  "https://storage.googleapis.com/azienda-agricola-stella.appspot.com/product-images/";

export default function (props) {
  const prodotto = props.prodotto;
  const backupProdotto = props.prodotto;
  const FileImageRef = React.createRef();

  const [nome, setNome] = useState(prodotto.name);
  const [descrizione, setDescrizione] = useState(prodotto.description);
  const [prezzo, setPrezzo] = useState(prodotto.price);
  const [unita, setUnita] = useState(prodotto.unit);
  const [categoria, setCategoria] = useState(prodotto.category);
  const [sottocategoria, setSottocategoria] = useState(prodotto.subcategory);
  const [immagine, setImmagine] = useState("");
  const [modifica, setModifica] = useState(false);
  const [successo, setSuccesso] = useState(false);
  const [errore, setErrore] = useState(false);
  const [testoSuccesso, setTestoSuccesso] = useState("");
  const [testoErrore, setTestoErrore] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      nome !== backupProdotto.name ||
      descrizione !== backupProdotto.description ||
      parseFloat(prezzo) !== parseFloat(backupProdotto.price) ||
      unita !== backupProdotto.unit ||
      parseInt(categoria) !== parseInt(backupProdotto.category) ||
      parseFloat(sottocategoria) !== parseFloat(backupProdotto.subcategory)
    ) {
      setModifica(true);
    } else {
      setModifica(false);
    }
  }, [nome, descrizione, prezzo, unita, categoria, sottocategoria]);

  useEffect(() => {
    setTimeout(() => {
      setSuccesso(false);
      setErrore(false);
      //setModifica(false);
    }, 2500);
  }, [successo, errore]);

  const onModifica = () => {
    //props.loading();
    setModifica(false);
    setLoading(true);
    debugger;

    let prodottoModificato = {
      id: prodotto.id,
      category: parseInt(categoria),
      subcategory: parseInt(sottocategoria),
      name: nome,
      description: descrizione,
      price: parseFloat(prezzo),
      unit: unita,
    };

    if (FileImageRef.current.files.length === 1) {
      // admin ha caricato una immagine
      Resizer.imageFileResizer(
        FileImageRef.current.files[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          debugger;
          chiamaApiUpload(uri, prodottoModificato);
        },
        "blob"
      );
    } else {
      chiamaApiModifica(prodottoModificato);
    }
  };

  const onElimina = () => {
    debugger;
    firebase
      .firestore()
      .collection("/products")
      .where("id", "==", props.prodotto.id)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        debugger;
        const prodottoDaCancellare = querySnapshot.docs[0];

        prodottoDaCancellare.ref
          .delete()
          .then((ok) => {
            // setSuccesso(true);
            // setTestoSuccesso("Prodotto rimosso con successo!");
          })
          .catch((err) => {
            // setErrore(true);
            // setTestoErrore("Il prodotto non è stato eliminato!");
          });
      })
      .catch(function (error) {
        //console.log("Error getting documents: ", error);
      });
  };

  const chiamaApiModifica = (prodottoModificato) => {
    console.log("prodotto modificato", prodottoModificato);

    mdw
      .callPostApi("modificaProdotto", prodottoModificato)
      .then((data) => {

        setLoading(false);

        //debugger;
        if (data) {
          //props.finish();
          setSuccesso(true);
          setTestoSuccesso("Prodotto modificato con successo!");
        } else {
          //errore
          //props.finish();
          setErrore(true);
          setTestoErrore("Errore modifica prodotto!");
        }
      })
      .catch((error) => {
        //errore
        //props.finish();
        setErrore(true);
        setTestoErrore("Errore modifica prodotto!");
      });
  };

  const chiamaApiUpload = (uri, prodottoModificato) => {
    //debugger;
    mdw
      .callPutApi("uploadImage", uri)
      .then((data) => {
        if (data.esito && data.idImage) {
          prodottoModificato.imageId = data.idImage;
        }

        chiamaApiModifica(prodottoModificato);
      })
      .catch((error) => {
        chiamaApiModifica(prodottoModificato);
      });
  };

if(loading) {
  return (
    <Spinner testo="Modifica prodotto" />
  )
}


  return (
    <>
      <div
        className="modal fade"
        id={"modal-" + prodotto.id}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Elimina prodotto
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Sei sicuro di voler rimuovere il prodotto{" "}
              <b>{props.prodotto.name}</b> dal tuo catalogo? L'operazione sarà
              irreversibile.
            </div>
            <div className="modal-footer justify-content-center flex-row">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                <FontAwesomeIcon icon="hand-paper" /> NO
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={onElimina}
              >
                <FontAwesomeIcon icon="folder-minus" /> SI!
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card my-3">
        <div className="card-header bg-gradient-primary text-white">
          <MobileView>
            Prodotto # <small>{prodotto.id.split('-')[0] + '...' + prodotto.id.split('-')[4]}</small>
          </MobileView>
          <BrowserView>
            Prodotto # <small className="font-weight-bold">{prodotto.id}</small>
          </BrowserView>
        </div>
        <div className="card-body">
        
        <div
          className="alert alert-success"
          role="alert"
          style={{ display: successo ? "block" : "none" }}
        >
          {testoSuccesso}
        </div>
        <div
          className="alert alert-danger"
          role="alert"
          style={{ display: errore ? "block" : "none" }}
        >
          {testoErrore}
        </div>
        <form>
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Nome</label>
                <input
                  className="form-control font-weight-bold"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => {
                    setNome(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Descrizione</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Descrizione"
                  value={descrizione}
                  onChange={(e) => {
                    setDescrizione(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Categoria</label>
                <select
                  className="form-control"
                  type="text"
                  placeholder="Categoria"
                  value={categoria}
                  onChange={(e) => {
                    setCategoria(e.currentTarget.value);
                  }}
                >
                  {jsonCategorie.categorie.map((categoria) => (
                    <option value={categoria.id} key={categoria.id}>
                      {categoria.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Sotto categoria</label>
                <select
                  className="form-control"
                  type="text"
                  placeholder="Sotto categoria"
                  value={sottocategoria}
                  onChange={(e) => {
                    setSottocategoria(e.currentTarget.value);
                  }}
                >
                  {jsonCategorie.sottocategorie.map((subcategoria) => (
                    <option value={subcategoria.id} key={subcategoria.id}>
                      {subcategoria.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Prezzo (€)</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Prezzo"
                  value={prezzo}
                  onChange={(e) => {
                    setPrezzo(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Unità (/€)</label>
                <select
                  className="form-control"
                  type="text"
                  placeholder="Unità"
                  value={unita}
                  onChange={(e) => {
                    setUnita(e.currentTarget.value);
                  }}
                >
                  <option value="kg">€/kg</option>
                  <option value="mazzo">€/mazzo</option>
                  <option value="mazzetto">€/mazzetto</option>
                  <option value="cadauno">€/cadauno</option>
                  <option value="cestino">cestino</option>
                </select>
              </div>
            </div>
          </div>

    

          <div className="form-row">
            <div className="col">
              <div className="container d-flex justify-content-center align-items-baseline">
                <img
                  className="img-fluid"
                  src={urlImmagine + prodotto.imageId}
                />
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>File Immagine</label>
                <input
                  type="file"
                  data-browse="Seleziona immagine"
                  className="form-control-file"
                  accept="image/jpeg"
                  ref={FileImageRef}
                  value={immagine}
                  onChange={(e) => {
                    setModifica(true);
                    setImmagine(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

        </form>
      
        </div>
        <div className="card-footer d-flex align-items-baseline justify-content-center">
          <button
                type="button"
                className="btn btn-warning btn-lg btn-block m-2"
                onClick={onModifica}
                disabled={!modifica}
              >
            <FontAwesomeIcon icon="edit" /> MODIFICA
          </button>
          <button
                  type="button"
                  className="btn btn-danger btn-lg btn-block m-2"
                  data-toggle="modal"
                  data-target={"#modal-" + props.prodotto.id}
                >
                  <FontAwesomeIcon icon="folder-minus" /> ELIMINA
                </button>
        </div>
      </div>

      
    </>
  );
}
