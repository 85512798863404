import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardInviaScontrino from "./CardInviaScontrino";
import { BrowserView, MobileView } from "react-device-detect";
import CardSottotitolo from "../components/CardSottotitolo";
const jsonFasceConsegna = require("../utils/fasceConsegna.json");

export default function ({ ordine, comuni, utenti }) {
  //debugger;
  // recupero il comune di questo ordine
  const comune = comuni.filter(
    (el) => el.id === parseInt(ordine.address.place)
  )[0];
  // recupero l'utente di questo ordine
  const utente = utenti.filter((el) => el.uid === ordine.user)[0];
  const utenteValido = utente ? true : false;
  let hasEmail = false;
  if(utenteValido) {
    hasEmail = utente.email !== "" ? true : false;
  }
  else {
    // utente non più esistente!
  }

  return (
    <div className="container-fluid">

    <BrowserView>

    <CardSottotitolo
                titolo="DETTAGLI ORDINE"
                sottotitolo=""
              />

    <ul className="list-group">
        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            {/* <div className="font-weight-bold">Data ordine</div> */}
            <div>
              <FontAwesomeIcon icon={["fas", "calendar-day"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Data ordine</span>
            </div>
            <div>{ordine.purchaseDate.toDate().toLocaleDateString()}</div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            {/* <div className="font-weight-bold">Stato</div> */}
            <div>
              <FontAwesomeIcon icon={["fas", "hourglass-end"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Stato</span>
            </div>
            <div>
              {ordine.completed ? (
                <FontAwesomeIcon icon={["fas", "circle"]} color="green" />
              ) : (
                <FontAwesomeIcon icon={["fas", "circle"]} color="red" />
              )}
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            {/* <div className="font-weight-bold">Costo consegna</div> */}
            <div>
              <FontAwesomeIcon icon={["fas", "euro-sign"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Costo consegna</span>
            </div>
            <div>{ordine.deliveryPrice} €</div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            {/* <div className="font-weight-bold">Comune</div> */}
            <div>
              <FontAwesomeIcon icon={["fas", "map-marked-alt"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Comune</span>
            </div>
            <div>{comune.nome}</div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            {/* <div className="font-weight-bold">Indirizzo</div> */}
            <div>
              <FontAwesomeIcon icon={["fas", "map-marked-alt"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Indirizzo</span>
            </div>
            <div>
              {ordine.address.address}
              {", "}
              {ordine.address.streetNumber}
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            {/* <div className="font-weight-bold">Citofono</div> */}
            <div>
              <FontAwesomeIcon icon={["fas", "user"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Citofono</span>
            </div>
            <div>{ordine.address.intercom}</div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            {/* <div className="font-weight-bold">Pagamento</div> */}
            <div>
              <FontAwesomeIcon icon={["fas", "euro-sign"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Pagamento</span>
            </div>
            <div>{ordine.payment === 0 ? "Satispy" : "Contanti"}</div>
          </div>
        </li>

        <li className="list-group-item">
          <div className="d-flex justify-content-between">
            {/* <div className="font-weight-bold">Fascia di consegna</div> */}
            <div>
              <FontAwesomeIcon icon={["fas", "truck-loading"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Fascia di consegna</span>
            </div>
              <div>{jsonFasceConsegna.fasce.filter(f => f.id === ordine.timeSlot)[0].value}</div>
          </div>
        </li>

        {ordine.note ? (
          <li className="list-group-item">
            <div className="d-flex justify-content-between">
              {/* <div className="font-weight-bold">Note</div> */}
              <div>
              <FontAwesomeIcon icon={["fas", "sticky-note"]} color="grey" />
              {' '}
              <span className="font-weight-bold">Note</span>
            </div>
              <div className="font-italic">{ordine.note}</div>
            </div>
          </li>
        ) : (
          <></>
        )}

      </ul>
    </BrowserView>


    <CardSottotitolo
                titolo="PRODOTTI ORDINE"
                sottotitolo=""
              />
    
    <div className="table-responsive my-2">
            <table className="table table-sm table-bordered table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Unità</th>
                    <th scope="col">Quantità</th>
                  </tr>
                </thead>
                <tbody>
                  {ordine.cart.map((prodotto, i) => (
                    <tr key={prodotto.id}>
                      <td>{prodotto.name}</td>
                      <td>{prodotto.unit}</td>
                      <td>{prodotto.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>



      {
        utenteValido ?
        hasEmail ?
        ordine.completed ?
        <div className="container mt-4">
          <div className="alert alert-success text-wrap" role="alert">
            Il cliente è già stato informato dello scontrino e il relativo costo da pagare
          </div>
        </div>
        :
        <>
          <CardInviaScontrino ordine={ordine} email={utente.email} />
          <p>
            <FontAwesomeIcon icon={["fas", "asterisk"]} color="red" />{' '}
            Eventualmente il cliente è contattabile <img alt="WhatsApp" className="img-fluid" src="/images/wa-logo.svg" style={{ width: "2rem" }} />:{' '}
            <b>
              <a href={ "whatsapp://send?text=Ciao, ti invio lo scontrino del tuo ordine...&phone=+39" + utente.phone }>{utente.phone}</a>
              </b>
          </p>
        </>
       : (
        <div className="container mt-4">
          <div className="alert alert-warning text-wrap" role="alert">
            Il cliente di questo ordine non ha una email associata. E'
            comunque reperibile al numero <b>{utente.phone}</b> per informarlo dello scontrino e del totale da pagare
          </div>
        </div>
      )
        :
        <div className="container mt-4">
          <div className="alert alert-warning text-wrap" role="alert">
            Il cliente di questo ordine ha eliminato il suo account, dunque non è più reperibile. L'ordine è da annullare!
          </div>
        </div>
      }

    </div>
  );
}
