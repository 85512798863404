import React from 'react';
import {Helmet} from "react-helmet";
const seoProperties = require("../utils/seoProperties.json");

export default function({ page }) {
    
    //debugger;

    const titolo = seoProperties[page].title;
    const descrizione = seoProperties[page].description;

    return (
        <Helmet>
            <title>Stella Porto A Casa | {titolo}</title>
            <meta name="description" content={descrizione} />
        </Helmet>
    )
}