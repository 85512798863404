import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Modal, Button} from 'react-bootstrap';
import CardSottotitolo from "../components/CardSottotitolo";
import * as mdw from "../middleware";
import firebase from "../components/Firebase";

export default function ({ ordine, email }) {
  const [scontrino, setScontrino] = useState("");
  const [scontrinoBase64, setScontrinoBase64] = useState(null);
  const [note, setNote] = useState("");
  const [prezzoFinale, setPrezzoFinale] = useState(0);
  const [inviaAbilitato, setInviaAbilitato] = useState(false);
  const [mostraModalEsito, setMostraModalEsito] = useState(false);
  const [messaggioEsito, setMessaggioEsito] = useState("");

  const FileImageRef = React.createRef();

  useEffect(() => {
    if (scontrinoBase64 !== null && prezzoFinale > 0) {
      setInviaAbilitato(true);
    } else {
      setInviaAbilitato(false);
    }
  }, [note, scontrinoBase64, prezzoFinale]);

  const onInviaScontrino = (e) => {
    e.preventDefault();

    const oggettoInvia = {
      scontrino: scontrinoBase64,
      note: note,
      prezzo: prezzoFinale,
      ordine: ordine,
      email: email,  // email di ricezione scontrino
      deliveryDay: ordine.deliveryDay.toDate() // converto la data di consegna per la email da inviare
    };

    console.log(oggettoInvia);

    debugger;
    mdw
      .callPostApi("inviaScontrino", oggettoInvia)
      .then((data) => {
        if (data) {
          setMessaggioEsito("Lo scontrino è stato inviato correttamente al cliente!");
          setMostraModalEsito(true);
          finalizzaOrdineFirebase();
        } else {
          setMessaggioEsito("Purtroppo si sono verificati errori durante l'invio dello scontrino.");
          setMostraModalEsito(true);
        }
      })
      .catch((error) => {
        setMessaggioEsito("Purtroppo si sono verificati errori durante l'invio dello scontrino.");
        setMostraModalEsito(true);
      });
  };

  const onChangeFile = (e) => {
    setScontrino(e);

    if (FileImageRef.current.files.length === 1) {
      getBase64(FileImageRef.current.files[0]);
    } else {
      setInviaAbilitato(false);
    }
  };

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //console.log(reader.result);
      setScontrinoBase64(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }; 


const finalizzaOrdineFirebase = () => {

  debugger;
    firebase
    .firestore()
    .collection("/orders").where('id', '==', ordine.id).limit(1).get().then((querySnapshot) => {
        debugger;
        const ordineDaFinalizzare = querySnapshot.docs[0];

        ordineDaFinalizzare.ref.update({ completed: true, price: parseFloat(prezzoFinale) }).then( ok => {
            console.log("Ordine finalizzato");
        })
        .catch(err => {
          console.log("Ordine NON finalizzato: errore!");
        })
    })
    .catch(function(error) {
      console.log("Ordine NON finalizzato: errore!");
    });
}

  return (
    <div className="card-invia-scontrino my-4 p-4 border rounded border-info">

      <Modal show={mostraModalEsito} onHide={() => setMostraModalEsito(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Esito invio scontrino</Modal.Title>
        </Modal.Header>
        <Modal.Body>{messaggioEsito}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMostraModalEsito(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <CardSottotitolo
                titolo="GEST. SCONTRINO"
                sottotitolo=""
              />
      
      <div>
      <form onSubmit={onInviaScontrino}>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label>
                    Immagine scontrino{" "}
                    <small style={{ color: "red", fontWeight: "bold" }}>
                      *
                    </small>
                  </label>
                  <input
                    type="file"
                    data-browse="Seleziona immagine scontrino"
                    className="form-control-file"
                    accept="image/jpeg"
                    value={scontrino}
                    onChange={(e) => onChangeFile(e.currentTarget.value)}
                    ref={FileImageRef}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label>Note cliente</label>
                  <textarea
                    className="form-control"
                    placeholder="Scrivi eventuali note da comunicare al cliente"
                    value={note}
                    onChange={(e) => setNote(e.currentTarget.value)}
                    rows="2"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label>
                    Prezzo finale{" "}
                    <small style={{ color: "red", fontWeight: "bold" }}>
                      *
                    </small>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Prezzo finale"
                    value={prezzoFinale}
                    onChange={(e) => {
                      setPrezzoFinale(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col text-center">
                <button
                  type="submit"
                  className="btn btn-lg mt-2 btn-success"
                  disabled={!inviaAbilitato}
                >
                  <FontAwesomeIcon icon="share" /> INVIA SCONTRINO <FontAwesomeIcon icon="envelope" />
                </button>
              </div>
            </div>
          </form>
      </div>

     
    </div>
  );
}
