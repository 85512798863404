import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import cookie from "react-cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import * as mdw from "./middleware";
import firebase from "./components/Firebase";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoginPage from "./pages/LoginPage";
import ProfiloPage from "./pages/ProfiloPage";
import ShoppingPage from "./pages/ShoppingPage";
import HomePage from "./pages/HomePage";
import CompletaOrdinePage from "./pages/CompletaOrdinePage";
import GestioneCatalogoPage from "./pages/GestioneCatalogoPage";
import GestioneOrdiniPage from "./pages/GestioneOrdiniPage";
import StampaOrdiniPage from "./pages/StampaOrdiniPage";
import RegistrazionePage from "./pages/RegistrazionePage";
import GiorniConsegnePage from "./pages/GiorniConsegnePage";
import UtentiPage from "./pages/UtentiPage";
import { DatabaseProvider } from "./components/DatabaseContext";
import { ParametersProvider } from "./components/ParametersContext";
import GiorniConsegneEditPage from "./pages/GiorniConsegneEditPage";

export default function App() {
  const [authentication, setAuthentication] = useState({
    isAdmin: false,
    isAuth: false,
    initializing: true,
  });
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      //debugger;
      if (user) {
        setCurrentUser(user);
        getUserInfo(user.uid);
        user.getIdTokenResult().then((idTokenResult) => {
          //console.log(idTokenResult.claims);

          if (idTokenResult.claims.admin) {
            setAuthentication({
              isAuth: true,
              isAdmin: true,
              initializing: false,
            });
          } else {
            setAuthentication({
              isAuth: true,
              isAdmin: false,
              initializing: false,
            });
          }
        });
      } else {
        setAuthentication({
          isAuth: false,
          isAdmin: false,
          initializing: false,
        });

        console.log("Info utente rimosse");
        // localstorage remove comuni/prodotti/params?
        cookie.remove("stella_user");
      }
      // piallo tutta la sessione
      // localStorage.removeItem("prodotti");
      // sessionStorage.removeItem("prodottiBck");
      // sessionStorage.removeItem("prezzoFinale");
      // sessionStorage.removeItem("costoConsegna");
      localStorage.clear();
    });
  }, [setAuthentication]);

  const getUserInfo = (uid) => {
    //debugger;
    if (!cookie.load("stella_user")) {
      // recupera le info da db dell'utente che si è appena loggato
      /*
      mdw
        .callPostApi("getUserInfo", { uid })
        .then((data) => {
          console.log("Info utente recuperate", data);
          cookie.save("stella_user", data);
        })
        .catch(() => {
          console.log("errore reperimento user info");
          cookie.remove("stella_user");
        });
        */
       firebase
       .firestore()
       .collection("/users")
       .where('uid', '==', uid)
       .limit(1)
       .get()
       .then((querySnapshot) => {

        const ut = querySnapshot.docs[0].data();
        cookie.save("stella_user", ut);
     })
     .catch( error => {
      console.log("errore reperimento user info");
      cookie.remove("stella_user");
     });
        
    }
  };

  function AdminRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          authentication.isAuth && authentication.isAdmin ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  function AuthRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          authentication.isAuth ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  function NoAuthRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          authentication.isAuth ? (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        }
      />
    );
  }

  return (
    <Router>
      <DatabaseProvider>
        <ParametersProvider>
        <Header user={currentUser} authentication={authentication} />
        <main
          className=""
          style={{ minHeight: "80vh", paddingTop: 70 }}
        >
          {authentication.initializing ? (
            <></>
          ) : (
            <Switch>
              {/* ROTTE PUBBLICHE */}
              <Route exact path="/" component={HomePage} />

              <Route path="/shop" component={ShoppingPage} />
              <Route path="/completa-ordine" component={CompletaOrdinePage} />
              <Route path="/giorni-consegne" component={GiorniConsegnePage} />

              <NoAuthRoute path="/login">
                <LoginPage />
              </NoAuthRoute>

              <NoAuthRoute path="/registrazione">
                <RegistrazionePage />
              </NoAuthRoute>

              {/* ROTTE AUTH */}
              <AuthRoute path="/profilo">
                <ProfiloPage />
              </AuthRoute>

              {/* ROTTE ADMIN */}
              <AdminRoute path="/gestione-catalogo">
                <GestioneCatalogoPage />
              </AdminRoute>

              <AdminRoute path="/gestione-ordini">
                <GestioneOrdiniPage />
              </AdminRoute>

              <AdminRoute path="/stampa-ordini">
                <StampaOrdiniPage />
              </AdminRoute>

              <AdminRoute path="/giorni-consegne-edit">
                <GiorniConsegneEditPage />
              </AdminRoute>

              <AdminRoute path="/utenti">
                <UtentiPage />
              </AdminRoute>

              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          )}
        </main>
        <Footer />
        </ParametersProvider>
      </DatabaseProvider>
    </Router>
  );
}
