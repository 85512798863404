import firebase from "./components/Firebase";
import _ from "lodash";
const jsonCategorie = require("./utils/categorie.json");

export const getNomeGiornoByNumero = (numeroGiorno) => {
  switch (numeroGiorno) {
    case 1:
      return "LUNEDI";
    case 2:
      return "MARTEDI";
    case 3:
      return "MERCOLEDI";
    case 4:
      return "GIOVEDI";
    case 5:
      return "VENERDI";
    default:
      return "";
  }
};

export const diff_minutes = (dt2, dt1) => {

  try {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  } catch (error) {
    return 0;
  }
};


export const addDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
};

export const subDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() - days);
  return copy;
};

export const isWeekday = (date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

// serve per visualizzare i prezzi nel formato corretto
export const toFixedPrice = (price) => {

  return parseFloat(price).toFixed(2).replace(".", ",");
}


export const getEndpointApi = () => {

  if(process.env.REACT_APP_LOCALHOSTING === "true")
  {
      return process.env.REACT_APP_ENDPOINT_API_LOCALHOSTING;
  }
  else {
      return process.env.REACT_APP_ENDPOINT_API;
  }
}


export const isLocalhosting = () => {

  if(process.env.REACT_APP_LOCALHOSTING === "true")
  {
      return true;
  }
  else {
      return false;
  }
}


export const writeLog = (event, result, errorMessage, paramsToLog) => {

  firebase.firestore().collection("event_logs")
  .add({
        datetime: new Date(),
        uid: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
        result: result,
        event: event,
        errorMessage: errorMessage ? errorMessage : null,
        params: paramsToLog ? JSON.stringify(paramsToLog) : null
    })
    .then(function(docRef) {
        //console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error) {
        //console.error("Error adding document: ", error);
    });
}

export const getCategoriaById = (id) => {

  let index = _.findIndex(jsonCategorie.categorie, function(c) { return c.id === id; });

  return jsonCategorie.categorie[index];

}

export const getSottocategoriaById = (id) => {
  
  let index = _.findIndex(jsonCategorie.sottocategorie, function(c) { return c.id === id; });

  return jsonCategorie.sottocategorie[index];

}