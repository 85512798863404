import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function(props) {
    return (
        <div className="jumbotron">
            <h1 className="display-4"><FontAwesomeIcon icon={["far", "frown"]} />{' '}{props.titolo}</h1>
            <p className="lead">{props.sottotitolo}</p>
            <hr className="my-4"/>
            <a className="btn btn-warning btn-lg" href="/" role="button">Torna alla Home</a>
            </div>
    )
}