import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import {motion} from 'framer-motion';
const jsonCategorie = require("../utils/categorie.json");
const genericFunction = require("../genericFunctions");


const dimensioniCardMobile = {
  width: "20rem",
  maxWidth: "25rem",
}

const dimensioniCardDesktop = {
  width: "20rem",
  maxWidth: "25rem"
}

export default function (props) {
  const [counterCart, setCounterCart] = useState(props.quantita);
  const immagine = "https://storage.googleapis.com/azienda-agricola-stella.appspot.com/product-images/" + props.prodotto.imageId;

  var delta = 0;
  switch (props.prodotto.unit) {
    case "kg":
      delta = 0.250;
      break;
    case "mazzo":
      delta = 1.000;
      break;
    case "cadauno":
      delta = 1;
      break;
    case "cestino":
      delta = 1;
      break;
    default:
      delta = 0.500;
      break;
  }

  const incrementaValore = (e) => {
    debugger;
    let quantita = parseFloat(
      parseFloat(e.currentTarget.dataset.value) + delta
    );

    if (quantita <= 0) {
      quantita = 0;
      setCounterCart(quantita);
    }
    else if(props.prodotto.unit === "kg") {
      setCounterCart(quantita.toFixed(3));
    }
    else {
      setCounterCart(quantita);
    }

    // passo al padre il num di elementi del prodotto con id id
    props.onValueCartChange(props.prodotto.id, quantita, "add");
  };

  const decrementaValore = (e) => {
    let quantita = parseFloat(
      parseFloat(e.currentTarget.dataset.value) - delta
    );

    if (quantita <= 0) {
      quantita = 0;
      setCounterCart(quantita);
    }
    else if(props.prodotto.unit === "kg") {
      setCounterCart(quantita.toFixed(3));
    }
    else {
      setCounterCart(quantita);
    }
    
    // passo al padre il num di elementi del prodotto con id id
    props.onValueCartChange(props.prodotto.id, quantita, "del");
  };

  return (
    <motion.div
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        className="card m-4 border-0" style={ isMobile ? dimensioniCardMobile : dimensioniCardDesktop }>
      <img
        className="card-img-top"
        alt="Immagine prodotto"
        style={{ height: "15rem", objectFit: "cover", borderRadius: "1.5rem" }}
        src={immagine}
      />
      <div className="card-body">
        <h5
          className="card-title font-weight-bold"
          style={{ fontSize: "x-large" }}
        >
          {props.prodotto.name}
        </h5>

        <div className="product-description my-2">
          <p>{props.prodotto.description}</p>
        </div>

        <div className="product-category">

          <span className="text-muted">

            {
              <>
              <img className="rounded" style={{ width: "1rem" }} src={genericFunction.getCategoriaById(props.prodotto.category).img} />
              {' '}
              {genericFunction.getCategoriaById(props.prodotto.category).value}
              </>
            }



            {/* {
              jsonCategorie.categorie[props.prodotto.category] !== undefined ? (
                <>
                <img className="rounded" style={{ width: "1rem" }} src={jsonCategorie.categorie[props.prodotto.category].img} />
                {jsonCategorie.categorie[props.prodotto.category].value}
                </>
              )
              :
              (
                <>Oooooooooooooooooooooooooooooooooooooo</>
              )
            } */}


            {/* {
              jsonCategorie.categorie[props.prodotto.category].img ?
              <>
              <img className="rounded" style={{ width: "1rem" }} src={jsonCategorie.categorie[props.prodotto.category].img} />
              {' '}
              </>
              :
              <></>
            }

            {jsonCategorie.categorie[props.prodotto.category].value} */}
          </span>
        </div>
        <div className="product-subcategory text-muted my-2">
          {/* {
            jsonCategorie.sottocategorie[props.prodotto.subcategory].img ?
            <>
              <img className="rounded" style={{ width: "1rem" }} src={jsonCategorie.sottocategorie[props.prodotto.subcategory].img} />
              {' '}
            </>
            :
            <></>
          }
          <span>{jsonCategorie.sottocategorie[props.prodotto.subcategory].value}</span> */}

            {
              <>
              <img className="rounded" style={{ width: "1rem" }} src={genericFunction.getSottocategoriaById(props.prodotto.subcategory).img} />
              {' '}
              {genericFunction.getSottocategoriaById(props.prodotto.subcategory).value}
              </>
            }


        </div>

        <div
          className="card-footer d-flex flex-row justify-content-between align-items-center"
        >
          {props.prodotto.price ? (
            <div>
              
                <span
                  className="product-price font-weight-bold"
                  style={{ fontSize: "large" }}
                >
                  {genericFunction.toFixedPrice(props.prodotto.price)}
                </span>{' '}
                <small>(€/{props.prodotto.unit})</small>
            </div>
          ) : (
            <div>
              
                <span
                  className="product-price"
                  style={{ fontWeight: 600, fontSize: "large" }}
                >
                  {props.prodotto.unit}
                </span>
              
            </div>
          )}

          <div className="d-flex align-items-baseline"
            // style={{
            //   display: "flex",
            //   flexDirection: "row",
            //   alignItems: "baseline",
            // }}
          >
            <button
              type="button"
              disabled={ counterCart === 0 }
              className="btn btn-secondary rounded-circle"
              data-value={counterCart}
              onClick={decrementaValore}
            >
              <FontAwesomeIcon icon="minus" />
            </button>
            <div className="mx-1">
              <h3>
                <span className="badge badge-light">
                {counterCart}
              </span>
              </h3>
              
            </div>

            <button
              type="button"
              className="btn btn-success rounded-circle"
              data-value={counterCart}
              onClick={incrementaValore}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
