import React, { useState, useEffect, createContext } from 'react';
import firebase from "../components/Firebase";
const genericFunction = require("../genericFunctions");

export const DatabaseContext = createContext();

export const DatabaseProvider = props => {

    const [comuniProdotti, setComuniProdotti] = useState(
            {   comuni: localStorage.getItem('comuni') ? JSON.parse(localStorage.getItem('comuni')) : [],
                prodotti: localStorage.getItem('prodotti') ? JSON.parse(localStorage.getItem('prodotti')) : []}
        );
    
    useEffect( () => {

        debugger;
         let lastUpdateMin = 0;

         if(localStorage.getItem('stellaLastUpdateCP')) {
             lastUpdateMin = genericFunction.diff_minutes(new Date(), new Date(JSON.parse(localStorage.getItem('stellaLastUpdateCP'))));
         }
        
        // ogni 24h. refresho da db
        if((comuniProdotti.comuni.length === 0 && comuniProdotti.prodotti.length === 0) || lastUpdateMin > 1440 ) {
            let oggettino = {comuni: [], prodotti: []};
    
            firebase
              .firestore()
              .collection("/places")
              .get()
              .then((querySnapshot) => {
                
                querySnapshot.forEach(function (doc) {
                    oggettino.comuni.push(doc.data());
                });
        
            firebase
                .firestore()
                .collection("/products")
                .get()
                .then((querySnapshot) => {
                
                // se utente non loggato, delete price
                const utenteLog = firebase.auth().currentUser;
                
                let prodotto = {};
                querySnapshot.forEach(function (doc) {
                    prodotto = doc.data();

                    if(!utenteLog) {
                        delete prodotto.price;
                    }

                    oggettino.prodotti.push(prodotto);
                });

                setComuniProdotti(oggettino);
                localStorage.setItem('comuni', JSON.stringify(oggettino.comuni));
                localStorage.setItem('prodotti', JSON.stringify(oggettino.prodotti));
                localStorage.setItem('stellaLastUpdateCP', JSON.stringify(new Date()));
                console.log("db cp new");
                });
            });
         }
         else {
             console.log("db cp old");
         }

    }, []);


    return (
        <DatabaseContext.Provider value={[comuniProdotti, setComuniProdotti]}>
            {props.children}
        </DatabaseContext.Provider>
    )
}