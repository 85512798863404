import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function({idOrdine, email, nuovaPassword}) {

    return (
        <div className="jumbotron">
            <h1 className="display-4"><FontAwesomeIcon icon={["fas", "shopping-cart"]} />{' '}Ordine concluso</h1>
            <p className="lead">Complimenti, hai terminato l'invio del tuo ordine.</p>
            <p className="">Questo è il numero d'ordine: <b>{idOrdine}</b></p>
            
            {
                email ?
                <p className="">Riceverai a breve una email all'indirizzo <span className="font-italic">{email}</span> con tutti i dettagli.</p>
                :
                <></>
            }

            {
                nuovaPassword ?
                <p className="">Segnati la tua nuova password per accedere in modo più rapido: <b>{nuovaPassword}</b></p>
                :
                <></>
            }

        <p className="font-italic"><FontAwesomeIcon icon="info-circle" className="fa-2x" color="green" />{' '}I prezzi dei prodotti variano giornalmente, non garantiamo quindi che i prezzi che hai appena visionato siano gli stessi di quando ti consegneremo la merce. In fase di consegna sei libero di non ritirare il prodotto che ha subito un cambiamento di prezzo.</p>

            <hr className="my-4"/>
            <a className="btn btn-warning btn-lg" href="/" role="button">Torna alla Home</a>
        </div>
    )
}