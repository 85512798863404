import React from "react";
import ReactDOM from "react-dom";
import "./custom.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope as faEnvelopeSolid,
  faThumbsUp,
  faCartArrowDown,
  faShoppingCart,
  faTruckMoving,
  faMapMarkedAlt,
  faMobile,
  faGlobeEurope,
  faAppleAlt,
  faSeedling,
  faLeaf,
  faEgg,
  faSpa,
  faFan,
  faEuroSign,
  faMinus,
  faPlus,
  faInfoCircle,
  faPhone,
  faSignOutAlt,
  faUserEdit,
  faSignInAlt,
  faShoppingBasket,
  faMoneyCheckAlt,
  faCircle,
  faListUl,
  faCalendarDay,
  faShare,
  faPrint,
  faInfo,
  faExclamationCircle,
  faUserSlash,
  faUserSecret,
  faCookieBite,
  faUserPlus,
  faFolderPlus,
  faFolderMinus,
  faEdit,
  faHandPaper,
  faTruck,
  faAsterisk,
  faHourglassEnd,
  faUser,
  faTruckLoading,
  faStickyNote,
  faBorderAll,
  faQuoteLeft,
  faQuoteRight,
  faStar,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFrown as faFrownRegular,
  faCopyright as faCopyrightRegular,
  faArrowAltCircleUp,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faInstagram,
  faEnvelopeSolid,
  faMapMarkedAlt,
  faMobile,
  faGlobeEurope,
  faAppleAlt,
  faSeedling,
  faLeaf,
  faEgg,
  faSpa,
  faFan,
  faEuroSign,
  faMinus,
  faPlus,
  faFrownRegular,
  faTruckMoving,
  faCartArrowDown,
  faShoppingCart,
  faThumbsUp,
  faInfoCircle,
  faPhone,
  faSignInAlt,
  faSignOutAlt,
  faUserEdit,
  faShoppingBasket,
  faMoneyCheckAlt,
  faCircle,
  faListUl,
  faCalendarDay,
  faShare,
  faPrint,
  faInfo,
  faExclamationCircle,
  faUserSlash,
  faCopyrightRegular,
  faUserSecret,
  faCookieBite,
  faUserPlus,
  faFolderPlus,
  faFolderMinus,
  faEdit,
  faHandPaper,
  faTruck,
  faAsterisk,
  faHourglassEnd,
  faUser,
  faTruckLoading,
  faStickyNote,
  faBorderAll,
  faArrowAltCircleUp,
  faQuoteLeft,
  faQuoteRight,
  faStar,
  faTimes
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*

<React.StrictMode>
      <App />
  </React.StrictMode>

*/
