import React from 'react';
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import {isWebpSupported} from 'react-image-webp/dist/utils';

export default function({ image, position, text }) {

    return (
        <div className="container d-flex my-5">

            {
                position === "left" ?
                <>

                    {/* <picture className="d-flex">
                        <source srcSet={"images/pierpaolo/" + image + ".webp"} type="image/webp" className="w-50 rounded-pill" style={{ objectFit: "cover" }} />
                        <source srcSet={"images/pierpaolo/" + image + ".jpg"} type="image/jpeg" className="w-50 rounded-pill" style={{ objectFit: "cover" }} /> 
                        <img src={"images/pierpaolo/" + image + ".jpg"} alt={"Immagine Pierpaolo " + image} className="w-50 rounded-pill" style={{ objectFit: "cover" }} />
                    </picture> */}
                    {/* {isWebpSupported() ? <img className="w-50 rounded-pill" style={{ objectFit: "cover" }} alt={"Immagine Pierpaolo " + image} src={"images/pierpaolo/" + image + ".webp"} /> : <img className="w-50 rounded-pill" style={{ objectFit: "cover" }} alt={"Immagine Pierpaolo " + image} src={"images/pierpaolo/" + image + ".jpg"} /> } */}
                
                    <img className="w-50 rounded-pill" style={{ objectFit: "cover" }} alt={"Immagine Pierpaolo " + image} src={"images/pierpaolo/" + image + ".jpg"} />

            <div className="container d-flex text-center align-items-center justify-content-center">

                <div className="text-center">
                    {
                        isMobile ?
                        <h6><FontAwesomeIcon icon={["fas", "quote-left"]} />{' '}<span className="text-monospace">{text}</span>{' '}<FontAwesomeIcon icon={["fas", "quote-right"]} /></h6>
                        :
                        <h3><FontAwesomeIcon icon={["fas", "quote-left"]} />{' '}<span className="text-monospace">{text}</span>{' '}<FontAwesomeIcon icon={["fas", "quote-right"]} /></h3>
                    }
                </div>
                
            </div>
                </>
                :
                <>
                <div className="container d-flex text-center align-items-center justify-content-center">
                <div className="text-center">
                    {
                        isMobile ?
                        <h6><FontAwesomeIcon icon={["fas", "quote-left"]} />{' '}<span className="text-monospace">{text}</span>{' '}<FontAwesomeIcon icon={["fas", "quote-right"]} /></h6>
                        :
                        <h3><FontAwesomeIcon icon={["fas", "quote-left"]} />{' '}<span className="text-monospace">{text}</span>{' '}<FontAwesomeIcon icon={["fas", "quote-right"]} /></h3>
                    }
                </div>
                
            </div>
            {/* {isWebpSupported() ? <img className="w-50 rounded-pill" style={{ objectFit: "cover" }} alt={"Immagine Pierpaolo " + image} src={"images/pierpaolo/" + image + ".webp"} /> : <img className="w-50 rounded-pill" style={{ objectFit: "cover" }} alt={"Immagine Pierpaolo " + image} src={"images/pierpaolo/" + image + ".jpg"} /> } */}
               
                    {/* <picture className="d-flex">
                        <source srcSet={"images/pierpaolo/" + image + ".webp"} type="image/webp" className="w-50 rounded-pill" style={{ objectFit: "cover" }} />
                        <source srcSet={"images/pierpaolo/" + image + ".jpg"} type="image/jpeg" className="w-50 rounded-pill" style={{ objectFit: "cover" }} /> 
                        <img src={"images/pierpaolo/" + image + ".jpg"} alt={"Immagine Pierpaolo " + image} className="w-50 rounded-pill" style={{ objectFit: "cover" }} />
                    </picture> */}
                
                <img className="w-50 rounded-pill" style={{ objectFit: "cover" }} alt={"Immagine Pierpaolo " + image} src={"images/pierpaolo/" + image + ".jpg"} />

                </>
            }

        </div>
    )
}
    
