import React, { useState, useEffect } from "react";
import firebase from "../components/Firebase";
import CardTitolo from "../components/CardTitolo";
import RowComuneModifica from "../components/RowComuneModifica";
const genericFunction = require("../genericFunctions");

export default function() {

    const [listaComuni, setListaComuni] = useState([]);

    useEffect(() => {
        recuperaComuni();
      }, []);

    const recuperaComuni = () => {
    //debugger;
    firebase
        .firestore()
        .collection("/places")
        .orderBy('nome')
        .get()
        .then((querySnapshot) => {
        let lista = [];
        querySnapshot.forEach(function (doc) {
            lista.push(doc.data());
        });

        setListaComuni(lista);
        });
    };


    return (
        <>
            <CardTitolo titolo="MODIFICA GIORNI CONSEGNE" sottotitolo="Visiona e/o modifica i giorni gratuiti delle tue consegne" />
            
            <div className="container table-responsive">
                <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">Comune</th>
                        <th scope="col">Giorno di consegna gratuito</th>
                    </tr>
                </thead>
                <tbody>
                    {listaComuni.map((comune, i) => (
                        <RowComuneModifica key={i} comune={comune} />
                    ))}
                </tbody>
                </table>
          </div>
            
        </>
        
    )
}