import React, { createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from "react-to-print";

export default function ({ ordine }) {
  const RifTabella = createRef();

  //console.log("------->", ordine);

  const stampaTabella = useReactToPrint({
    content: () => RifTabella.current,
    copyStyles: true,
  });

  return (
    <div className="container" ref={RifTabella}>
      <div className="row align-items-center justify-content-between">
        {/* <div className="col">
                <span className="font-weight-bold"><FontAwesomeIcon icon={["fas", "cart-arrow-down"]} /> # {' '}{ordine.id}</span>
              </div>
              <div className="col">
                <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={stampaTabella}
              >
                <FontAwesomeIcon icon={["fas", "print"]} /> STAMPA
              </button>
              </div> */}
        <div>
          {/* <span className="font-weight-bold">
            <FontAwesomeIcon icon={["fas", "cart-arrow-down"]} /> # {ordine.id}
          </span> */}
          <span className="font-weight-bold"><FontAwesomeIcon icon={["fas", "user"]} /> {ordine.address.intercom}</span>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-warning btn-sm"
            onClick={stampaTabella}
          >
            <FontAwesomeIcon icon={["fas", "print"]} /> STAMPA
          </button>
        </div>
      </div>

      <ul className="list-group my-2">
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <span className="font-weight-bold">Prodotto</span>
          <span className="font-weight-bold">Quantità</span>
        </li>

        {ordine.cart.map((prodotto) => (
          <li
            key={prodotto.id}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            <span className="font-italic">{prodotto.name}</span>
            <span>
              {prodotto.quantity} {prodotto.unit}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

/*
<table ref={RifTabella} className="table table-sm">

        <thead>
          <tr>
            <td colSpan="10">
<div className="d-flex justify-content-between align-items-center">
            <span className="font-weight-bold"><FontAwesomeIcon icon={["fas", "cart-arrow-down"]} /> # {' '}{ordine.id}</span>
            <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={stampaTabella}
            >
              <FontAwesomeIcon icon={["fas", "print"]} /> STAMPA ORDINE
            </button>
          </div>
            </td>
          
            
          </tr>
        </thead>

        <thead className="bg-active">
          <tr className="table-active text-nowrap">
            <th scope="col">Prodotto</th>
            <th scope="col">Quantità</th>
          </tr>
        </thead>

        <tbody>

        {ordine.cart.map( (prodotto) => (

          <tr key={prodotto.id}>
            <td>{prodotto.name}</td>
            <td>{prodotto.quantity}{' '}{prodotto.unit}</td>
          </tr>
          
        ))}

        </tbody>
      </table>


*/
